import React from 'react';
import './style.scss';
import { Row, Col, Tooltip } from "antd";
import moment from "moment/moment";
import { ReactComponent as External } from "../../../img/default-svg/externalLink.svg";
import { useTranslation } from "react-i18next";

const WebhooksList = ({ data, setDataId }) => {
  const { t } = useTranslation();

  return (
    <div className="webhooks-list">
      {!data?.length  ? (
        <div className="webhooks-list__empty">
          {t('webhooks.emptyList')}
        </div>
      ) : (
        <div className="webhooks-list__table">
          <Row className="webhooks-list__table--row">
            <Col className="webhooks-list__table--col" span={6}>
              {t('webhooks.id')}
            </Col>
            <Col className="webhooks-list__table--col" span={5}>
              {t('webhooks.type')}
            </Col>
            <Col className="webhooks-list__table--col" span={5}>
              {t('webhooks.time')}
            </Col>
            <Col className="webhooks-list__table--col" span={3}>
              {t('webhooks.numberRequests')}
            </Col>
            <Col className="webhooks-list__table--col" span={2}>
              {t('webhooks.status')}
            </Col>
            <Col className="webhooks-list__table--col webhooks-list__table--external" span={3}>
              {t('webhooks.viewData')}
            </Col>
          </Row>
          <div>
            {data?.map((item, index) => (
              <Row className="webhooks-list__table--wrapper" key={index}>
                <Col span={6} className="webhooks-list__table--data">
                  <Tooltip
                    placement="topLeft"
                    title={item?._id}
                  >
                    {item?._id}
                  </Tooltip>
                </Col>
                <Col span={5} className="webhooks-list__table--data">
                  {item?.data?.typeTransaction}
                </Col>
                <Col span={5} className="webhooks-list__table--data">
                  {moment(item?.createdAt).format('YYYY-MM-DD, HH:mm')}
                </Col>
                <Col span={3} className="webhooks-list__table--data">
                  {item?.countRequest}
                </Col>
                <Col span={2} className="webhooks-list__table--data webhooks-list__table--data-status">
                  <div className={`${item?.status ? 'webhooks-list__table--active' : 'webhooks-list__table--notactive'}`}></div>
                  {String(item?.status).charAt(0).toUpperCase() + String(item?.status).slice(1)}
                </Col>
                <Col span={3} className="webhooks-list__table--data webhooks-list__table--external">
                  <External onClick={() => setDataId(item?._id)} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
    </div>
  )
};

export default WebhooksList;