import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { notification, Empty } from 'antd';
import { formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  getMerchantWallets,
  getMoreMerchantWallets,
  deleteMerchantWallet,
  resetPageCounter,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase
} from '../../redux/actions/merchantWallets';
import ReactPaginate from 'react-paginate';
import { getMerchantData } from '../../redux/actions/merchantData';
import ClientsWalletsFilters from './ClientsWalletsFilters';
import ClientsWalletsPagination from './ClientsWalletsPagination';
import ReplenishModal from '../ReplenishModal/ReplenishModal';
import PrivateKeyModal from './PrivateKeyModal';
import Wallet from './Wallet';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './WalletsList.scss';
import { adminApi } from "../../service/admin-api";
import { getUserMerchants } from "../../redux/actions/adminPanel/adminUsers";

const ClientsWalletsList = ({
  formName,
  merchantId,
  networkFilter,
  wallets,
  typeWallet,
  currencies,
  getMerchantWallets,
  getMoreMerchantWallets,
  page,
  pages,
  countItem,
  resetPageCounter,
  walletsFilters,
  fetching,
  getMerchantData,
  merchantData,
  deleteMerchantWallet,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase,
  type = 'user',
  userId,
  userMerchants
}) => {
  const [walletId, setWalletId] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const { t } = useTranslation();
  const [showWalletBy, setShowWalletBy] = useState(null);
  const [hideZeroCheckbox, setHideZeroCheckbox] = useState(
    JSON.parse(localStorage.getItem('cpayHideZeroBalancesClient')) || false
  );
  const [walletsAdmin, setWalletsAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminPage, setAdminPage] = useState(1);
  const [adminPages, setAdminPages] = useState(1);
  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const location = window.location.href;

  const isAdmin = location?.includes('admin');

  const defaultFilterValues = {
    order: 'DESC',
    search: '',
    limit: 10,
    currencyIds: currencies.map(currency => currency._id),
    typeNetwork: networkFilter,
  };

  const getAdminClientsWallet = async (body) => {
    setLoading(true);

    try {
      const response = await adminApi.getAdminMerchantWallet(userId, merchantId, body);
      if (response?.data) {
        setAdminPage(response?.data?.page);
        setAdminPages(response?.data?.pages);
        setWalletsAdmin(response?.data?.entities)
      } else {
        setWalletsAdmin([]);
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false);
  }

  useEffect(() => {
    if (isAdmin) {
      dispatch(getUserMerchants(userId))
    }
  }, [userId]);

  useEffect(() => {
    if (userMerchants?.length > 0 && merchantId && isAdmin) {
      const name = userMerchants?.filter((item) => item?._id === merchantId);
      setName(name[0]?.name)
    } else {
      setName('')
    }
  }, [userMerchants, merchantId])

  useEffect(() => {
    if (merchantId && type !== 'admin') {
      getMerchantData(merchantId);
    } else if (defaultFilterValues?.currencyIds?.length > 1 && isAdmin) {
      const { currencyIds } = defaultFilterValues;
      getAdminClientsWallet({
        ...defaultFilterValues,
        order: walletsFilters?.order || 'DESC',
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        positiveBalance: hideZeroCheckbox
      });
    } else if (defaultFilterValues?.currencyIds?.length === 0 && isAdmin) {
      setWalletsAdmin([]);
    }
  }, [merchantId, networkFilter, hideZeroCheckbox]);

  useEffect(() => {
    if (defaultFilterValues?.currencyIds?.length > 1 && isAdmin) {
      const { currencyIds } = defaultFilterValues;
      getAdminClientsWallet({
        ...defaultFilterValues,
        order: walletsFilters?.order || 'DESC',
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        positiveBalance: hideZeroCheckbox
      });
    } else if (defaultFilterValues?.currencyIds?.length === 0) {
      setWalletsAdmin([]);
    }
  }, [walletsFilters?.order]);

  useEffect(() => {
    if (
      Object.keys(walletsFilters).length !== 0 &&
      walletsFilters.constructor === Object &&
      merchantId &&
      merchantId.length &&
      type !== 'admin'
    ) {
      if (walletsFilters.limit || walletsFilters.order) {
        submitFilters();
      }
    }
  }, [merchantId, walletsFilters.limit, walletsFilters.order, networkFilter, hideZeroCheckbox]);

  const handleReplenishButton = (address, currency, isModalOpen) => {
    setWalletAddress(address);
    setWalletCurrency(currency);
    setIsReplenishModalOpen(isModalOpen);
  };

  const openNotification = () => {
    notification.success({
      message: t('copied'),
      duration: 2,
    });
  };

  const openModal = walletId => {
    setWalletId(walletId);
    setIsKeyModalOpen(true);
  };

  const closeModal = () => {
    setIsKeyModalOpen(false);
    resetTwoFaState(false);
  };

  const loadMoreWallets = page => {
    const { order, limit, search, currencyIds } = walletsFilters;
    getMoreMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: page,
      typeNetwork: networkFilter,
      positiveBalance: hideZeroCheckbox
    });
  };

  const loadMoreAdminWallets = selectedPage => {
    const { order, limit, search, currencyIds } = walletsFilters;
    const pageNumber = selectedPage.selected + 1;
    getAdminClientsWallet({
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: pageNumber,
      typeNetwork: networkFilter,
      positiveBalance: hideZeroCheckbox
    }).then((response) => {
      if (response?.data) {
        setWalletAddress(response?.data?.entities)
      }
    });
  }

  const submitFilters = () => {
    const { order, limit, search, currencyIds } = walletsFilters;
    resetPageCounter();
    if (type !== 'admin') {
      getMerchantWallets(merchantId, {
        order: order,
        limit: limit,
        search: search.trim(),
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        positiveBalance: hideZeroCheckbox
      }).then(() => setIsFetched(true));
    } else if (isAdmin) {
      getAdminClientsWallet({
        order: order,
        limit: limit,
        search: search.trim(),
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        positiveBalance: hideZeroCheckbox
      }).then((response) => {
        if (response?.data) {
          setWalletAddress(response?.data?.entities)
        }
      });
    }
  };

  const resetFilters = () => {
    const { currencyIds } = defaultFilterValues;
    if (type !== 'admin') {
      getMerchantWallets(merchantId, {
        ...defaultFilterValues,
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        positiveBalance: hideZeroCheckbox
      }).then(() => setIsFetched(true));
    } else if (isAdmin) {
      getAdminClientsWallet({
        ...defaultFilterValues,
        currencyIds: currencyIds.join(',') || undefined,
        page: 1,
        typeNetwork: networkFilter,
        positiveBalance: hideZeroCheckbox
      }).then((response) => {
        if (response?.data) {
          setWalletsAdmin(response?.data?.entities)
        }
      });
    }
  };

  const walletsToUse = type !== 'admin' ? wallets : walletsAdmin;
  const canShowWallets = type !== 'admin' ? isFetched : !loading;

  return (
    <React.Fragment>
      <ReplenishModal
        walletAddress={walletAddress}
        walletCurrency={walletCurrency}
        isReplenishModalOpen={isReplenishModalOpen}
        handleReplenishButton={handleReplenishButton}
      />
      <PrivateKeyModal
        merchantId={merchantId}
        walletId={walletId}
        setWalletId={setWalletId}
        isKeyModalOpen={isKeyModalOpen}
        closeModal={closeModal}
        openNotification={openNotification}
        getWalletPrivateKey={getWalletPrivateKey}
        showWalletBy={showWalletBy}
        getWalletMnemonicPhrase={getWalletMnemonicPhrase}
        getPassphrase={getPassphrase}
      />
      {currencies && currencies.length && (
        <ClientsWalletsFilters
          formName={formName}
          merchantName={!isAdmin ? merchantData.name : name}
          currencies={currencies}
          initialValues={defaultFilterValues}
          submitFilters={submitFilters}
          resetFilters={resetFilters}
          fetching={fetching}
          hideZeroCheckbox={hideZeroCheckbox}
          setCheckbox={setHideZeroCheckbox}
        />
      )}
      {canShowWallets && walletsToUse && walletsToUse?.length > 0 ? (
        <>
          <div className={`wallets-page ${fetching ? 'wallets-page_fetching' : ''}`}>
            {walletsToUse.map((wallet, index) => {
              return (
                <Wallet
                  key={index}
                  merchantId={merchantId}
                  wallet={wallet}
                  typeWallet={typeWallet}
                  page={page}
                  pages={pages}
                  countItem={countItem}
                  walletsFilters={walletsFilters}
                  openModal={openModal}
                  openNotification={openNotification}
                  deleteMerchantWallet={deleteMerchantWallet}
                  handleReplenishButton={handleReplenishButton}
                  setWalletAsMain={setWalletAsMain}
                  currencies={currencies}
                  networkFilter={networkFilter}
                  getWalletPrivateKey={getWalletPrivateKey}
                  getWalletMnemonicPhrase={getWalletMnemonicPhrase}
                  getPassphrase={getPassphrase}
                  setShowWalletBy={setShowWalletBy}
                />
              );
            })}
          </div>
          {!fetching && page && pages && type !== 'admin' ? (
            <ClientsWalletsPagination
              page={page}
              pages={pages}
              walletsFilters={walletsFilters}
              loadMoreWallets={loadMoreWallets}
              submitFilters={submitFilters}
              formName={formName}
            />
          ) : null}

          {adminPages > 1 && type === 'admin' ? (
            <ReactPaginate
              pageCount={adminPages}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={loadMoreAdminWallets}
              containerClassName="pagination"
              activeClassName="active"
              previousLabel={"«"}
              nextLabel={"»"}
              breakLabel={"..."}
              forcePage={adminPage - 1}
            />
          ) : null}
        </>
      ) : (
        !fetching && !loading &&  <Empty description={t("nodata")}/>

      )}
      {(fetching || loading) && (
        <div className="wallets__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  wallets: state.merchantWallets.data,
  page: state.merchantWallets.page,
  pages: state.merchantWallets.pages,
  countItem: state.merchantWallets.countItem,
  fetching: state.merchantWallets.fetching,
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
  walletsFilters: formValueSelector(props.formName)(state, 'search', 'order', 'limit', 'currencyIds'),
  userMerchants: state.adminUsers.userMerchants.data,
});

const mapDispatchToProps = dispatch => ({
  getMerchantWallets: getMerchantWallets(dispatch),
  getMoreMerchantWallets: getMoreMerchantWallets(dispatch),
  getMerchantData: getMerchantData(dispatch),
  deleteMerchantWallet: deleteMerchantWallet(dispatch),
  setWalletAsMain: setWalletAsMain(dispatch),
  resetTwoFaState: resetTwoFaState(dispatch),
  getWalletPrivateKey: getWalletPrivateKey(dispatch),
  resetPageCounter,
  getWalletMnemonicPhrase: getWalletMnemonicPhrase(dispatch),
  getPassphrase: getPassphrase(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsWalletsList);
