import React, { useEffect } from 'react';
import Merchant from '../../../components/Merchant/Merchant';
import { getUserMerchants } from "../../../redux/actions/adminPanel/adminUsers";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import './styles.scss';
import WalletCreateModal from "../../../components/WalletCreateModal/WalletCreateModal";
import PageHeader from "../../../layout/PageHeader";

const UserAccounts = ({ getUserMerchants, userMerchants, userMerchantsFetching }) => {
  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      getUserMerchants(userId);
    }
  }, [userId]);

  const WalletCreateComponent = () => (
    <WalletCreateModal
      typeWallet="merchant"
      filtersFormName="merchantWalletsFilters"
    />
  );

  return (
    <div className="admin-accounts">
      {userMerchants?.length ? (
        <PageHeader merchants={true} showCreate={false} showBackButton={true} CreateModalComponent={WalletCreateComponent} />
      ) : null}
      {userMerchants?.length && userMerchants?.map((item) => (
        <Merchant type="admin" merchant={item} userRole="corporate" />
      ))}
    </div>
  )
};

const mapStateToProps = state => ({
  userMerchants: state.adminUsers.userMerchants.data,
  userMerchantsFetching: state.adminUsers.userMerchants.fetching,
});

const mapDispatchToProps = {
  getUserMerchants,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccounts);