import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { notification, Empty } from 'antd';
import { formValueSelector } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import {
  getMerchantWallets,
  getMoreMerchantWallets,
  deleteMerchantWallet,
  resetPageCounter,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase
} from '../../redux/actions/merchantWallets';
import ReactPaginate from 'react-paginate';
import { getMerchantBalances, getMerchantData } from '../../redux/actions/merchantData';
import WalletsFilters from './WalletsFilters';
import WalletsPagination from './WalletsPagination';
import ReplenishModal from '../ReplenishModal/ReplenishModal';
import PrivateKeyModal from './PrivateKeyModal';
import Wallet from './Wallet';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './WalletsList.scss';
import { getUserMerchants } from "../../redux/actions/adminPanel/adminUsers";
import {
  checkAutosignStatus,
  getWithdrawWallets,
  setPasswordSignature,
  downloadAutosign,
  checkPasswordStatus,
} from '../../redux/actions/withdraw';
import WalletItem from './WalletItem';
import useWindowSize from "../../utils/resizeHook";
import { adminApi } from "../../service/admin-api";

const WalletsList = ({
  formName,
  merchantId,
  networkFilter,
  wallets,
  typeWallet,
  currencies,
  userId,
  getMerchantWallets,
  getMoreMerchantWallets,
  page,
  pages,
  countItem,
  autosignStatus,
  resetPageCounter,
  walletsFilters,
  fetching,
  getMerchantData,
  merchantData,
  deleteMerchantWallet,
  setWalletAsMain,
  resetTwoFaState,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  getPassphrase,
  setPasswordSignature,
  downloadAutosign,
  passwordStatus,
  checkAutosignStatus,
  checkPasswordStatus,
  autosignStep,
  downloadAutosignStep,
  downloadError,
  type = 'user',
  userMerchants
}) => {
  const [socket, setSocket] = useState(undefined);
  const [walletId, setWalletId] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [walletCurrency, setWalletCurrency] = useState('');
  const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [adminPage, setAdminPage] = useState(1);
  const [adminPages, setAdminPages] = useState(1);
  const [hideZeroCheckbox, setHideZeroCheckbox] = useState(
    JSON.parse(localStorage.getItem('cpayHideZeroBalancesMerchant')) || false
  );
  const [walletsAdmin, setWalletsAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [showWalletBy, setShowWalletBy] = useState(null);
  const [name, setName] = useState('')
  const { currentWidth } = useWindowSize();
  const location = window.location.href;
  const dispatch = useDispatch();

  const isAdmin = location?.includes('admin');

  const { pathname } = useLocation();
  const isFromMerchantsWallets = pathname.includes('/merchants/wallets') || pathname === '/wallets';

  const defaultFilterValues = {
    order: 'DESC',
    search: '',
    limit: 10,
    currencyIds: currencies.filter(currency => currency.currencyType === 'currency').map(currency => currency._id),
    typeNetwork: networkFilter,
  };

  const getAdminMerchantWallet = async (body) => {
    setLoading(true);

    try {
      const response = await adminApi.getAdminMerchantWallet(userId, merchantId, body);
      if (response?.data) {
        setAdminPage(response?.data?.page);
        setAdminPages(response?.data?.pages);
        setWalletsAdmin(response?.data?.entities)
      } else {
        setWalletsAdmin([])
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false);
  }

  useEffect(() => {
    if (isAdmin) {
      dispatch(getUserMerchants(userId))
    }
  }, [userId]);

  useEffect(() => {
    if (userMerchants?.length > 0 && merchantId && isAdmin) {
      const name = userMerchants?.filter((item) => item?._id === merchantId);
      setName(name[0]?.name)
    } else {
      setName('')
    }
  }, [userMerchants, merchantId])

  useEffect(() => {
    if (merchantId && type !== 'admin') {
      getMerchantData(merchantId);
    } else if (defaultFilterValues?.currencyIds?.length > 1 && isAdmin) {
      const { currencyIds } = defaultFilterValues;
      getAdminMerchantWallet({
        ...defaultFilterValues,
        order: walletsFilters?.order || 'DESC',
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        showTokens: true,
        positiveBalance: hideZeroCheckbox
      });
    } else if (defaultFilterValues?.currencyIds?.length === 0 && isAdmin) {
      setWalletsAdmin([]);
    }
  }, [merchantId, networkFilter, hideZeroCheckbox]);

  useEffect(() => {
    if (defaultFilterValues?.currencyIds?.length > 1 && isAdmin) {
      const { currencyIds } = defaultFilterValues;
      getAdminMerchantWallet({
        ...defaultFilterValues,
        order: walletsFilters?.order || 'DESC',
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        showTokens: true,
        positiveBalance: hideZeroCheckbox
      });
    } else if (defaultFilterValues?.currencyIds?.length === 0) {
      setWalletsAdmin([]);
    }
  }, [walletsFilters?.order]);

  useEffect(() => {
    if (
      Object.keys(walletsFilters).length !== 0 &&
      walletsFilters.constructor === Object &&
      merchantId &&
      merchantId.length &&
      type !== 'admin'
    ) {
      if (walletsFilters.limit || walletsFilters.order) {
        submitFilters();
      }
    }
  }, [walletsFilters.limit, walletsFilters.order, networkFilter, hideZeroCheckbox]);

  const handleReplenishButton = (address, currency, isModalOpen) => {
    setWalletAddress(address);
    setWalletCurrency(currency);
    setIsReplenishModalOpen(isModalOpen);
  };

  const openNotification = () => {
    notification.success({
      message: t('copied'),
      duration: 2,
    });
  };

  const openModal = walletId => {
    setWalletId(walletId);
    setIsKeyModalOpen(true);
  };

  const closeModal = () => {
    setIsKeyModalOpen(false);
    resetTwoFaState(false);
  };

  const loadMoreWallets = page => {
    const { order, limit, search, currencyIds } = walletsFilters;
    getMoreMerchantWallets(merchantId, {
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: page,
      typeNetwork: networkFilter,
      showTokens: false,
      positiveBalance: hideZeroCheckbox
    });
  };

  const loadMoreAdminWallets = selectedPage => {
    const { order, limit, search, currencyIds } = walletsFilters;
    const pageNumber = selectedPage.selected + 1;
    getAdminMerchantWallet({
      order: order,
      limit: limit,
      search: search.trim(),
      currencyIds: currencyIds.join(',') || undefined,
      typeWallet: typeWallet,
      page: pageNumber,
      typeNetwork: networkFilter,
      showTokens: true,
      positiveBalance: hideZeroCheckbox
    }).then((response) => {
      if (response?.data) {
        setWalletAddress(response?.data?.entities)
      }
    });
  }

  const submitFilters = () => {
    const { order, limit, search, currencyIds } = walletsFilters;
    resetPageCounter();
    if (type !== 'admin') {
      getMerchantWallets(merchantId, {
        order: order,
        limit: limit,
        search: search.trim(),
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        showTokens: false,
        positiveBalance: hideZeroCheckbox
      }).then(() => setIsFetched(true));
    } else if (isAdmin) {
      getAdminMerchantWallet({
        order: order,
        limit: limit,
        search: search.trim(),
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        showTokens: true,
        positiveBalance: hideZeroCheckbox
      }).then((response) => {
        if (response?.data) {
          setWalletAddress(response?.data?.entities)
        }
      });
    }
  };

  const updateList = () => {
    submitFilters()
  }

  const resetFilters = () => {
    const { currencyIds } = defaultFilterValues;
    if (type !== 'admin') {
      getMerchantWallets(merchantId, {
        ...defaultFilterValues,
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        showTokens: false,
        positiveBalance: hideZeroCheckbox
      }).then(() => setIsFetched(true));
    } else if (isAdmin) {
      getAdminMerchantWallet({
        ...defaultFilterValues,
        currencyIds: currencyIds.join(',') || undefined,
        typeWallet: typeWallet,
        page: 1,
        typeNetwork: networkFilter,
        showTokens: true,
        positiveBalance: hideZeroCheckbox
      }).then((response) => {
        if (response?.data) {
          setWalletsAdmin(response?.data?.entities)
        }
      });
    }
  };

  useEffect(() => {
    if (type !== 'admin') {
      socket && socket.disconnect();
      setSocket(undefined);
      createSocket(merchantId);
    }
  }, [merchantId]);

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });
    socket.on('updatedBalance', () => {
      // const { currencyIds } = defaultFilterValues;
      if (merchantId) {
        //  getMerchantWallets(merchantId, {
        //   ...defaultFilterValues,
        //   currencyIds: currencyIds.join(',') || undefined,
        //   typeWallet: typeWallet,
        //   page: 1,
        //   typeNetwork: networkFilter,
        //   showTokens: false,
        // }, true).then(() => setIsFetched(true));
        // getMerchantBalances(merchantId, networkFilter, true)
        // getWithdrawWallets(merchantId, currencyIds.join(',') || undefined, networkFilter, true, true);
      }
    });
    socket.on('disconnect', () => {});
  };

  const walletsToUse = type !== 'admin' ? wallets : walletsAdmin;
  const canShowWallets = type !== 'admin' ? isFetched : !loading;

  console.log(loading)

  return (
    <React.Fragment>
      <ReplenishModal
        walletAddress={walletAddress}
        walletCurrency={walletCurrency}
        isReplenishModalOpen={isReplenishModalOpen}
        handleReplenishButton={handleReplenishButton}
      />
      <PrivateKeyModal
        merchantId={merchantId}
        walletId={walletId}
        setWalletId={setWalletId}
        isKeyModalOpen={isKeyModalOpen}
        closeModal={closeModal}
        openNotification={openNotification}
        getWalletPrivateKey={getWalletPrivateKey}
        showWalletBy={showWalletBy}
        getWalletMnemonicPhrase={getWalletMnemonicPhrase}
        getPassphrase={getPassphrase}
      />
      {currencies && currencies.length && (
        <WalletsFilters
          formName={formName}
          merchantName={!isAdmin ? merchantData.name : name}
          currencies={currencies}
          initialValues={defaultFilterValues}
          submitFilters={submitFilters}
          resetFilters={resetFilters}
          fetching={fetching}
          hideZeroCheckbox={hideZeroCheckbox}
          setCheckbox={setHideZeroCheckbox}
        />
      )}
      {canShowWallets && walletsToUse && walletsToUse?.length > 0 ? (
        <>
          <div className={`wallets-page ${fetching ? 'wallets-page_fetching' : ''}`}>
            {walletsToUse.map((wallet, index) => {
              return (
                <>
                  {index === 0 && currentWidth >= 1024 && (
                    <Row align="middle">
                      <Col span={1}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 1 : 2, offset: 0 }} xs={{ span: 3, offset: 1 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 7 : 8, offset: 0 }} xs={{ span: 17 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 2 : 7, offset: 1 }} xs={{ span: 11, offset: 5 }}>
                      </Col>
                      <Col sm={{ span: currentWidth >= 1024 ? 2 : 5, offset: 0 }} xs={{ span: 8 }}>
                      </Col>
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 3 : 10, offset: currentWidth >= 1024 ? 0 : 0 }}
                        xs={{ span: 10, offset: 5 }}
                      >
                      </Col>
                      {wallet.typeWallet === 'merchant' && type !== 'admin' && (
                        <Col
                          sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 2 }}
                          xs={{ span: 10, offset: 5 }}
                          style={{ marginLeft: '2%', textAlign: 'center', maxWidth: 'auto !important' }}
                        >
                          {t('merchants.security.autosignStatus')}
                        </Col>
                      )}
                      {wallet.typeWallet === 'merchant' && type !== 'admin' && (
                        <Col
                          sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 1 : 2 }}
                          xs={{ span: 10, offset: 5 }}
                          style={{ marginLeft: '4%', textAlign: 'center', maxWidth: 'auto !important' }}
                        >
                          {t('merchants.security.passwordStatus')}
                        </Col>
                      )}
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 2 : 2, offset: currentWidth >= 1024 ? 0 : 6 }}
                        xs={{ span: 4, offset: 1 }}
                      >
                      </Col>
                      <Col
                        sm={{ span: currentWidth >= 1024 ? 1 : 1, offset: currentWidth >= 1024 ? 0 : 1 }}
                        xs={{ span: 1, offset: 1 }}
                      >
                      </Col>
                    </Row>
                  )}
                  {/* TODO: - #CRYP-2220 - https://j.mng.ninja/browse/CRYP-2220 - added new UI,
                   but need to rewrite getWalletPrivateKey and getWalletMnemonicPhrase
                   functions and update reducers (new catching errors (if no 2fa)) 
                   Then remove previous implementation */}
                  <WalletItem
                    autosignStep={autosignStep}
                    downloadAutosignStep={downloadAutosignStep}
                    key={wallet._id}
                    merchantId={merchantId}
                    wallet={wallet}
                    type={type}
                    typeWallet={typeWallet}
                    page={page}
                    pages={pages}
                    countItem={countItem}
                    downloadAutosign={downloadAutosign}
                    walletsFilters={walletsFilters}
                    openModal={openModal}
                    setPasswordSignature={setPasswordSignature}
                    autosignStatus={autosignStatus}
                    openNotification={openNotification}
                    deleteMerchantWallet={deleteMerchantWallet}
                    handleReplenishButton={handleReplenishButton}
                    setWalletAsMain={setWalletAsMain}
                    checkAutosignStatus={checkAutosignStatus}
                    checkPasswordStatus={checkPasswordStatus}
                    passwordStatus={passwordStatus}
                    currencies={currencies}
                    networkFilter={networkFilter}
                    getWalletPrivateKey={getWalletPrivateKey}
                    getPassphrase={getPassphrase}
                    getWalletMnemonicPhrase={getWalletMnemonicPhrase}
                    setShowWalletBy={setShowWalletBy}
                    isFromMerchantsWallets={isFromMerchantsWallets}
                    resetTwoFaState={resetTwoFaState}
                    updateList={updateList}
                    downloadError={downloadError}
                  />
                </>
              );
            })}
          </div>
          {!fetching && page && pages && type !== 'admin' ? (
            <WalletsPagination
              page={page}
              pages={pages}
              walletsFilters={walletsFilters}
              loadMoreWallets={loadMoreWallets}
              submitFilters={submitFilters}
              formName={formName}
            />
          ) : null}

          {adminPages > 1 && type === 'admin' ? (
            <ReactPaginate
              pageCount={adminPages}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={loadMoreAdminWallets}
              containerClassName="pagination"
              activeClassName="active"
              previousLabel={"«"}
              nextLabel={"»"}
              breakLabel={"..."}
              forcePage={adminPage - 1}
            />
          ) : null}
        </>
      ) : (
        !fetching && !loading && <Empty description={t('nodata')} />
      )}
      {(fetching || loading) && (
        <div className="wallets__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  wallets: state.merchantWallets.data,
  page: state.merchantWallets.page,
  pages: state.merchantWallets.pages,
  countItem: state.merchantWallets.countItem,
  fetching: state.merchantWallets.fetching,
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
  autosignStatus: state.withdraw.autosignStatus,
  passwordStatus: state.withdraw.passwordStatus,
  walletsFilters: formValueSelector(props.formName)(state, 'search', 'order', 'limit', 'currencyIds'),
  autosignStep: state.withdraw.autosignStep,
  downloadAutosignStep: state.withdraw.downloadAutosignStep,
  downloadError: state.withdraw.downloadError,
  userMerchants: state.adminUsers.userMerchants.data,
});

const mapDispatchToProps = dispatch => ({
  getMerchantWallets: getMerchantWallets(dispatch),
  getMoreMerchantWallets: getMoreMerchantWallets(dispatch),
  getMerchantData: getMerchantData(dispatch),
  deleteMerchantWallet: deleteMerchantWallet(dispatch),
  setWalletAsMain: setWalletAsMain(dispatch),
  resetTwoFaState: resetTwoFaState(dispatch),
  getWalletPrivateKey: getWalletPrivateKey(dispatch),
  getPassphrase: getPassphrase(dispatch),
  resetPageCounter,
  getWalletMnemonicPhrase: getWalletMnemonicPhrase(dispatch),
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawWallets: getWithdrawWallets(dispatch),
  checkAutosignStatus: checkAutosignStatus(dispatch),
  setPasswordSignature: setPasswordSignature(dispatch),
  downloadAutosign: downloadAutosign(dispatch),
  checkPasswordStatus: checkPasswordStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletsList);
