import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ClientsWalletsList from '../../../components/WalletsPage/ClientsWalletsList';
import PageHeader from "../../../layout/PageHeader";
import WalletCreateModal from "../../../components/WalletCreateModal/WalletCreateModal";

const MerchantClientsPage = ({ userRole, networkFilter }) => {
  const { merchantId } = useParams();
  const { userId } = useParams();

  const WalletCreateComponent = () => (
    <WalletCreateModal
      merchantId={merchantId}
      typeWallet="user"
      createMode="type"
      filtersFormName="clientsWalletsFilters"
      networkFilter={networkFilter}
    />
  );

  return (
    <>
      <PageHeader showCreate={false} showBackButton={true} CreateModalComponent={WalletCreateComponent} />
      <div className="component" style={{ width: '100%'}}>
        <ClientsWalletsList
          formName="clientsWalletsFilters"
          merchantId={merchantId}
          typeWallet="user"
          networkFilter={networkFilter}
          userId={userId}
          type="admin"
        />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  userRole: state.user.data.roles,
  networkFilter: state.networkFilter,
});

export default connect(mapStateToProps)(MerchantClientsPage);