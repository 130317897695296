import { adminApi } from '../../../service/admin-api';
import { notificationsSuccessShow, notificationsErrorShow } from '../notifications';
import {
  swapExchangeError,
  swapExchangeGet,
  swapExchangeSet,
  swapSetAppTwoFa,
  swapSetEmailTwoFa,
  swapSetPhoneTwoFa
} from "../swap";

// GET ADMIN SWAP HISTORY

export const GET_ADMIN_SWAP_HISTORY_REQUEST = 'GET_ADMIN_SWAP_HISTORY_REQUEST';
export const GET_ADMIN_SWAP_HISTORY_SUCCESS = 'GET_ADMIN_SWAP_HISTORY_SUCCESS';
export const GET_ADMIN_SWAP_HISTORY_FAIL = 'GET_ADMIN_SWAP_HISTORY_FAIL';

const getAdminSwapHistoryRequest = () => ({
  type: GET_ADMIN_SWAP_HISTORY_REQUEST
});

const getAdminSwapHistorySuccess = payload => ({
  type: GET_ADMIN_SWAP_HISTORY_SUCCESS,
  payload
});

const getAdminSwapHistoryFail = payload => ({
  type: GET_ADMIN_SWAP_HISTORY_FAIL,
  payload
});

export const getAdminSwapHistory = dispatch => async (body) => {
  dispatch(getAdminSwapHistoryRequest());

  try {
    const response = await adminApi.getAdminSwapHistory(body);
    dispatch(getAdminSwapHistorySuccess(response.data));
  } catch (error) {
    dispatch(getAdminSwapHistoryFail(error?.data));
  }
};

export const SWAP_ADMIN_EXCHANGE_GET = 'SWAP_ADMIN_EXCHANGE_GET';
export const SWAP_ADMIN_EXCHANGE_ERROR = 'SWAP_ADMIN_EXCHANGE_ERROR';
export const SWAP_ADMIN_SET_APP_TWO_FA = 'SWAP_ADMIN_SET_APP_TWO_FA';
export const SWAP_ADMIN_SET_EMAIL_TWO_FA = 'SWAP_ADMIN_SET_EMAIL_TWO_FA';
export const SWAP_ADMIN_SET_PHONE_TWO_FA = 'SWAP_ADMIN_SET_PHONE_TWO_FA';
export const SWAP_ADMIN_EXCHANGE_SET = 'SWAP_ADMIN_EXCHANGE_SET';

export const swapAdminExchangeGet = () => ({
  type: SWAP_ADMIN_EXCHANGE_GET,
});

export const swapAdminExchangeError = data => ({
  type: SWAP_ADMIN_EXCHANGE_ERROR,
  payload: data,
});

export const swapAdminSetAppTwoFa = isAppTwoFa => ({
  type: SWAP_ADMIN_SET_APP_TWO_FA,
  isAppTwoFa,
});

export const swapAdminSetEmailTwoFa = isEmailTwoFa => ({
  type: SWAP_ADMIN_SET_EMAIL_TWO_FA,
  isEmailTwoFa,
});

export const swapAdminSetPhoneTwoFa = isPhoneTwoFa => ({
  type: SWAP_ADMIN_SET_PHONE_TWO_FA,
  isPhoneTwoFa,
});

export const swapAdminExchangeSet = data => ({
  type: SWAP_ADMIN_EXCHANGE_SET,
  payload: data,
});


export const makeAdminSwap = dispatch => async body => {
  dispatch(swapExchangeGet());
  let response;
  try {
    response = await adminApi.makeAdminSwap(body);
  } catch (error) {
    console.log(error);
    dispatch(swapExchangeError(error.data.message));
    if (!error.data?.message.includes("You don't have enough")) {
      if (error.data?.message.includes('Internal partner error')) {
        dispatch(notificationsErrorShow({ message: 'Internal partner error, please select another partner' }));
      } else {
        dispatch(notificationsErrorShow(error?.data));
      }
    }
  }
  if (response && response.data.twoFactorToken) {
    dispatch(swapSetAppTwoFa(true));
    return response;
  }

  if (response && response.data.emailSent) {
    dispatch(swapSetEmailTwoFa(true));
    return response;
  }

  if (response && response.data.codeSent) {
    dispatch(swapSetPhoneTwoFa(true));
    return response;
  }
  if (response && response.status === 'success') {
    dispatch(swapExchangeSet(response.data));
    return response;
  }
};