import React from 'react';
import SwapHistory from "../components/SwapHistory";

const AdminSwap = () => {
  return (
    <div>
      <SwapHistory type="admin" />
    </div>
  )
};

export default AdminSwap;