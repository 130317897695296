import {
  MERCHANT_WALLETS_RESET_PAGE,
  MERCHANT_WALLETS_GET,
  MERCHANT_WALLETS_GET_MORE,
  MERCHANT_WALLETS_SET,
  MERCHANT_WALLETS_SET_TOGETHER,
  MERCHANT_WALLETS_RESET,
  MERCHANT_WALLETS_CREATE,
  MERCHANT_WALLETS_DELETE,
  MERCHANT_WALLETS_GET_PRIVATE_KEY,
  MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS,
  MERCHANT_WALLETS_SET_APP_TWO_FA,
  MERCHANT_WALLETS_SET_EMAIL_TWO_FA,
  MERCHANT_WALLETS_RESET_TWO_FA,
  MERCHANT_WALLETS_SET_PHONE_TWO_FA,
  MERCHANT_WALLETS_ERROR,
  MERCHANT_WALLETS_GET_MNEMONIC_PHRASE,
  MERCHANT_WALLETS_GET_MNEMONIC_PHRASE_SUCCESS,
  MERCHANT_WALLETS_GET_PASSPHRASE,
  MERCHANT_WALLETS_GET_PASSPHRASE_SUCCESS,
  MERCHANT_WALLETS_SET_TOGETHER_SENT_TO,
  MERCHANT_WALLETS_SET_SENT_TO,
} from '../actions/merchantWallets';

export default function reducer(
  state = {
    data: [],
    dataSentTo: [],
    page: 1,
    pages: null,
    fetching: false,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    isPhoneTwoFa: false,
    modalFetching: false,
  },
  action
) {
  switch (action.type) {
    case MERCHANT_WALLETS_GET:
    case MERCHANT_WALLETS_GET_MORE:
      return {
        ...state,
        fetching: true,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_CREATE:
    case MERCHANT_WALLETS_DELETE:
    case MERCHANT_WALLETS_GET_PRIVATE_KEY:
    case MERCHANT_WALLETS_GET_MNEMONIC_PHRASE:
    case MERCHANT_WALLETS_GET_PASSPHRASE:
      return {
        ...state,
        modalFetching: true,
      };
    case MERCHANT_WALLETS_GET_PRIVATE_KEY_SUCCESS:
    case MERCHANT_WALLETS_GET_MNEMONIC_PHRASE_SUCCESS:
    case MERCHANT_WALLETS_GET_PASSPHRASE_SUCCESS:
      return {
        ...state,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET:
      return {
        ...state,
        data: action.payload.entities,
        page: action.payload.page,
        pages: action.payload.pages,
        countItem: action.payload.countItem,
        fetching: false,
      };
    case MERCHANT_WALLETS_SET_SENT_TO:
      return {
        ...state,
        dataSentTo: action.payload.entities,
        pageSentTo: action.payload.page,
        pagesSentTo: action.payload.pages,
        countItemSentTo: action.payload.countItem,
        fetching: false,
      };
    case MERCHANT_WALLETS_SET_TOGETHER:
      return {
        ...state,
        data: [...state.data, ...action.payload.entities],
        page: action.payload.page,
        pages: action.payload.pages,
        countItem: action.payload.countItem,
        fetching: false,
      };
    case MERCHANT_WALLETS_SET_TOGETHER_SENT_TO:
      return {
        ...state,
        dataSentTo: [...state.dataSentTo, ...action.payload.entities],
        pageSentTo: action.payload.page,
        pagesSentTo: action.payload.pages,
        countItemSentTo: action.payload.countItem,
        fetching: false,
      };
    case MERCHANT_WALLETS_RESET:
      return {
        data: [],
        dataSentTo: [],
        page: 1,
        pages: null,
        fetching: false,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET_APP_TWO_FA:
      return {
        ...state,
        isAppTwoFa: action.isAppTwoFa,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.isEmailTwoFa,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_SET_PHONE_TWO_FA:
      return {
        ...state,
        isPhoneTwoFa: action.isPhoneTwoFa,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_RESET_TWO_FA:
      return {
        ...state,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        isPhoneTwoFa: false,
        modalFetching: false,
      };
    case MERCHANT_WALLETS_RESET_PAGE:
      return {
        ...state,
        page: 1,
        pages: null,
      };
    case MERCHANT_WALLETS_ERROR:
      return {
        ...state,
        fetching: false,
        modalFetching: false,
      };
    default:
      return state;
  }
}
