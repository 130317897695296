import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HomeCurrency from '../components/HomeCurrency';
import HomeNftPreview from '../components/HomeNftPreview';
import HomeTransactions from '../components/HomeTransactions';
import CheckoutsLink from '../components/CheckoutsLink';
import { getTransactions, getMoreTransactions, downloadTransactionsInfo } from '../redux/actions/transactions';
import { getMerchantBalances } from '../redux/actions/merchantData';
import useWindowSize from '../utils/resizeHook';
import './Home.scss';
import { AppConfig } from '../config';
import WizzardBlock from '../components/WizzardBlock/WizardBlock';
import { useNftSupport } from '../utils/nftSupportContext';
import moment from "moment/moment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = ({
  merchantId,
  networkFilter,
  transactions,
  transactionsFetching,
  moreTransactionsFetching,
  countItem,
  merchantBalances,
  currencies,
  balancesFetching,
  getTransactions,
  getMoreTransactions,
  getMerchantBalances,
  balancesTotal,
  downloadTransactionsInfo,
  transInfoFetching,
  storedValue
}) => {
  const query = useQuery();

  const defaultParams = {
    search: query.get('checkoutId') || '',
    order: 'DESC',
    currencyId: '',
    type: 'replenishment,withdrawal,Error,refund,multisend,InternalTransfer,ExternalCall',
    page: 1,
    limit: 7,
    typeNetwork: networkFilter,
    onlyNft: false,
    nftType: undefined,
    withoutNft: !AppConfig.showNftBlock ? true : JSON.parse(localStorage.getItem('hideNft')) || false,
  };

  const [socket, setSocket] = useState(undefined);
  const [currencyId, setCurrencyId] = useState(defaultParams.currencyId);
  const [search, setSearch] = useState(defaultParams.search);
  const [order, setOrder] = useState(defaultParams.order);
  const [type, setType] = useState(defaultParams.type);
  const [page, setPage] = useState(defaultParams.page);
  const [onlyNft, setOnlyNft] = useState(defaultParams.onlyNft);
  const [nftType, setNftType] = useState(defaultParams.nftType);
  const [isHideZeroBalances, setIsHideZeroBalances] = useState(
    JSON.parse(localStorage.getItem('cpayHideZeroBalances')) || false
  );
  const [times, setTimes] = useState([]);
  const [hideNft, setHideNft] = useState(defaultParams.withoutNft);
  const limit = defaultParams.limit;
  const { currentWidth } = useWindowSize();

  const dateFrom = times?.length === 2 && Date.parse(moment(times[0]).format('YYYY-MM-DD') + 'T00:00:00');
  const dateTo = times?.length === 2 && Date.parse(moment(times[1]).format('YYYY-MM-DD') + 'T23:59:59');

  const { showNftSupportBlock } = useNftSupport();

  useEffect(() => {
    if (merchantId) {
      getMerchantBalances(merchantId, networkFilter, true);
      setSearch(defaultParams.search);
      setOrder(defaultParams.order);
      setType(defaultParams.type);
      setPage(defaultParams.page);
      setOnlyNft(defaultParams.onlyNft);
      setNftType(defaultParams.nftType);
      setHideNft(defaultParams.withoutNft);

      getTransactions(merchantId, {
        search,
        order,
        currencyId: currencyId || undefined,
        type,
        page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [merchantId, networkFilter, times]);

  useEffect(() => {
    localStorage.setItem('currId', currencyId);
    localStorage.setItem('search', search);
    localStorage.setItem('order', order);
    localStorage.setItem('type', type);
    localStorage.setItem('page', page);
    localStorage.setItem('nftType', nftType);
    localStorage.setItem('onlyNft', onlyNft);
    localStorage.setItem('hideNft', hideNft);
  }, [currencyId, search, order, type, page]);

  useEffect(() => {
    setPage(defaultParams.page);
    if (merchantId) {
      getTransactions(merchantId, {
        search,
        order,
        currencyId: currencyId || undefined,
        type,
        page: defaultParams.page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [currencyId, order, type, onlyNft, nftType, hideNft, times]);

  useEffect(() => {
    if (page > 1) {
      getMoreTransactions(merchantId, {
        search,
        order,
        currencyId: currencyId || undefined,
        type,
        page,
        limit,
        typeNetwork: networkFilter,
        onlyNft: onlyNft ? true : undefined,
        nftType: onlyNft ? nftType : undefined,
        withoutNft: hideNft ? true : undefined,
        from: dateFrom || undefined,
        to: dateTo || undefined,
      });
    }
  }, [page]);

  useEffect(() => {
    socket && socket.disconnect();
    // setSocket(undefined);
    createSocket(merchantId);
  }, [merchantId]);

  const createSocket = merchantId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
    });
    socket.on('connect', () => {
      socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
      setSocket(socket);
    });

    socket.on('pendingTransactionStatus', data => {
      if (merchantId) {
        getTransactions(
          merchantId,
          {
            search: localStorage.getItem('search'),
            order: localStorage.getItem('order'),
            currencyId: localStorage.getItem('currId') || undefined,
            type: localStorage.getItem('type'),
            page: defaultParams.page,
            limit,
            typeNetwork: networkFilter,
            onlyNft: localStorage.getItem('onlyNft') === 'true' ? true : undefined,
            nftType:
              localStorage.getItem('onlyNft') === 'true' &&
              ['erc721', 'erc1155'].includes(localStorage.getItem('nftType'))
                ? localStorage.getItem('nftType')
                : undefined,
            withoutNft: localStorage.getItem('hideNft') === 'true' ? true : undefined,
            from: dateFrom || undefined,
            to: dateTo || undefined,
          },
          true
        );
      }
    });

    socket.on('confirmedTransaction', data => {
      // console.log('incomingTransaction: ', data);
      if (merchantId && networkFilter === data.typeNetwork) {
        getTransactions(
          merchantId,
          {
            search: localStorage.getItem('search'),
            order: localStorage.getItem('order'),
            currencyId: localStorage.getItem('currId') || undefined,
            type: localStorage.getItem('type'),
            page: defaultParams.page,
            limit,
            typeNetwork: data.typeNetwork,
            onlyNft: localStorage.getItem('onlyNft') === 'true' ? true : undefined,
            nftType:
              localStorage.getItem('onlyNft') === 'true' &&
              ['erc721', 'erc1155'].includes(localStorage.getItem('nftType'))
                ? localStorage.getItem('nftType')
                : undefined,
            withoutNft: localStorage.getItem('hideNft') === 'true' ? true : undefined,
            from: dateFrom || undefined,
            to: dateTo || undefined,
          },
          true
        );

        if (data.systemStatus === 'Done') {
          getMerchantBalances(merchantId, networkFilter, true);
        }
        setPage(defaultParams.page);
      }
    });
    socket.on('incomingTransaction', data => {
      //  console.log('incomingTransaction: ', data);
      if (merchantId && networkFilter === data.typeNetwork) {
        getTransactions(
          merchantId,
          {
            search: localStorage.getItem('search'),
            order: localStorage.getItem('order'),
            currencyId: localStorage.getItem('currId') || undefined,
            type: localStorage.getItem('type'),
            page: defaultParams.page,
            limit,
            typeNetwork: data.typeNetwork,
            onlyNft: localStorage.getItem('onlyNft') === 'true' ? true : undefined,
            nftType:
              localStorage.getItem('onlyNft') === 'true' &&
              ['erc721', 'erc1155'].includes(localStorage.getItem('nftType'))
                ? localStorage.getItem('nftType')
                : undefined,
            withoutNft: localStorage.getItem('hideNft') === 'true' ? true : undefined,
            from: dateFrom || undefined,
            to: dateTo || undefined,
          },
          true
        );
        if (data.systemStatus === 'Done') {
          getMerchantBalances(merchantId, networkFilter, true);
        }
        setPage(defaultParams.page);
      }
    });
  };

  const setTime = (value) => setTimes(value);

  const submitSearch = search => {
    setSearch(search);
    setPage(defaultParams.page);
    getTransactions(merchantId, {
      search,
      order,
      currencyId: currencyId || undefined,
      typeNetwork: networkFilter,
      page: defaultParams.page,
      limit,
      onlyNft: onlyNft ? true : undefined,
      nftType: onlyNft ? nftType : undefined,
      withoutNft: hideNft ? true : undefined,
      from: dateFrom || undefined,
      to: dateTo || undefined,
    });
  };

  const resetFilters = () => {
    query.delete('checkoutId');
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);

    setSearch('');
    setOrder(defaultParams.order);
    setType(defaultParams.type);
    setPage(defaultParams.page);
    setOnlyNft(defaultParams.onlyNft);
    setNftType(defaultParams.nftType);
    setHideNft(defaultParams.withoutNft);
    setTimes([]);

    getTransactions(merchantId, { ...defaultParams, currencyId: currencyId || undefined, search: '' });
  };

  const resetFiltersChangeCurrency = () => {
    setSearch(defaultParams.search);
    setOrder(defaultParams.order);
    setType(defaultParams.type);
    setPage(defaultParams.type);
    setOnlyNft(defaultParams.onlyNft);
    setNftType(defaultParams.nftType);
    setHideNft(defaultParams.withoutNft);
    localStorage.setItem('search', defaultParams.search);
    localStorage.setItem('order', defaultParams.order);
    localStorage.setItem('type', defaultParams.type);
    localStorage.setItem('page', defaultParams.page);
    localStorage.setItem('nftType', defaultParams.nftType);
    localStorage.setItem('onlyNft', defaultParams.onlyNft);
    localStorage.setItem('hideNft', defaultParams.withoutNft);
  };

  return (
    <div className="home-page">
      <div className="home-page__left">
        {currentWidth < 1024 && storedValue && AppConfig.showNftBlock && (
          <WizzardBlock />
        )}
        <HomeCurrency
          merchantBalances={merchantBalances}
          balancesFetching={balancesFetching}
          currencies={currencies}
          currencyId={currencyId}
          setCurrencyId={setCurrencyId}
          isHideZeroBalances={isHideZeroBalances}
          setIsHideZeroBalances={setIsHideZeroBalances}
          balancesTotal={balancesTotal}
          getMerchantBalances={getMerchantBalances}
          merchantId={merchantId}
          networkFilter={networkFilter}
          resetFiltersChangeCurrency={resetFiltersChangeCurrency}
        />
      </div>
      <div className="home-page__right">
        {currentWidth >= 1024 && storedValue && AppConfig.showNftBlock && (
          <WizzardBlock />
        )}
        <HomeTransactions
          setTime={setTime}
          currencyId={currencyId}
          merchantBalances={merchantBalances}
          transactions={transactions}
          transactionsFetching={transactionsFetching}
          moreTransactionsFetching={moreTransactionsFetching}
          currencies={currencies}
          networkFilter={networkFilter}
          countItem={countItem}
          page={page}
          setPage={setPage}
          search={search}
          setSearch={setSearch}
          order={order}
          setOrder={setOrder}
          type={type}
          setType={setType}
          submitSearch={submitSearch}
          resetFilters={resetFilters}
          downloadTransactionsInfo={downloadTransactionsInfo}
          merchantId={merchantId}
          transInfoFetching={transInfoFetching}
          onlyNft={onlyNft}
          setOnlyNft={setOnlyNft}
          setHideNft={setHideNft}
          nftType={nftType}
          setNftType={setNftType}
          hideNft={hideNft}
          getTransactions={getTransactions}
          limit={limit}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  transactions: state.transactions.data,
  transactionsFetching: state.transactions.fetching,
  moreTransactionsFetching: state.transactions.loadMoreFetching,
  countItem: state.transactions.countItem,
  merchantBalances: state.merchantData.balances.balances || [],
  currencies: state.currencies.data,
  balancesFetching: state.merchantData.fetching,
  balancesTotal: state.merchantData.balances.total,
  transInfoFetching: state.transactions.download.fetching,
});

const mapDispatchToProps = dispatch => ({
  getTransactions: getTransactions(dispatch),
  getMoreTransactions: getMoreTransactions(dispatch),
  getMerchantBalances: getMerchantBalances(dispatch),
  downloadTransactionsInfo: downloadTransactionsInfo(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
