import React from 'react';
import { Row, Col } from 'antd';
import MerchantSettings from '../components/MerchantEdit/MerchantSettings';
import MerchantDataForm from '../components/MerchantEdit/MerchantDataForm';
import useWindowSize from '../utils/resizeHook';
import { InstructionCustomURL } from '../components/InstructionCustomURL/InstructionCustomURL';
import AutoswapDataForm from '../components/SettingsEdit/AutoswapDataForm';
import { AppConfig } from '../config';
import MerchantSecurity from '../components/MerchantEdit/MerchantSecurity';
import { connect } from "react-redux";
import SecurityDataForm from "../components/SettingsEdit/SecurityDataForm";
import WebhooksWrapper from "../components/Webhooks/WebhooksWrapper";

const MerchantEdit = ({ networkFilter, userRole }) => {
  const { currentWidth } = useWindowSize();

  return (
    <React.Fragment>
      <Row gutter={[20, 20]}>
        <Col span={currentWidth >= 1024 ? 6 : 24}>
          <MerchantSettings />
          {AppConfig.showDomainInstruction && <InstructionCustomURL />}
        </Col>
        <Col span={currentWidth >= 1024 ? 18 : 24}>
          <MerchantDataForm />
          {/* <MerchantSecurity />  //  Uncomment - #CRYP-2220 - https://j.mng.ninja/browse/CRYP-2220 */}
          {userRole.includes('corporate') && <SecurityDataForm />}
          {networkFilter === 'mainnet' && <AutoswapDataForm />}
          <WebhooksWrapper />
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  userRole: state.user.data.roles,
});

export default connect(mapStateToProps, null)(MerchantEdit);
