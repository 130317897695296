import {
  GET_ADMIN_SWAP_HISTORY_REQUEST,
  GET_ADMIN_SWAP_HISTORY_SUCCESS,
  GET_ADMIN_SWAP_HISTORY_FAIL
} from '../../actions/adminPanel/adminSwap';

export default function reducer(
  state = {
    history: {
      data: {
        page: 0,
        pages: 0,
        countItems: 0,
        entities: [],
      },
      fetching: false,
    },
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_SWAP_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: true
        }
      };
    case GET_ADMIN_SWAP_HISTORY_SUCCESS:
      return action.payload.page > 1
        ? {
          ...state,
          history: {
            ...state.history,
            data: {
              ...state.history.data,
              ...action.payload,
              entities: [...state.history.data.entities, ...action.payload.entities],
            },
            fetching: false,
          },
        }
        : {
          ...state,
          history: {
            ...state.history,
            data: {
              ...state.history.data,
              ...action.payload,
            },
            fetching: false,
          },
        };
    case GET_ADMIN_SWAP_HISTORY_FAIL:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: false,
        },
      };
    default:
      return state;
  }
}