import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
} from '../../../redux/actions/adminPanel/adminDashboard';
import { getAdminUsers } from '../../../redux/actions/adminPanel/adminUsers';
import TopFive from './TopFive';
import TableData from './TableData';
import DashboardHeader from './DashBoardHeader';
import LocationData from "./LocationData";
import './styles.scss';
import { clearLocalStorage } from './helpers';
import { scrollContainerIntoView } from '../../helpers/scrollIntoView';
import { getUserLocations } from "../../../redux/actions/adminPanel/adminSettings";

const currencyVolumesTrxArray = ['Replenishment', 'Withdrawal', 'SystemFee'];

const AdminDashboardPage = ({
  // STATE
  getCryptoCurrencyVolumeState,
  getCryptoCurrencyQuantityState,
  getCryptoCurrencyVolumeTrxByTypeState,
  getNewUsersState,
  getWithdrawTransactionsState,
  getReplenishTransactionsState,
  getSystemFeeTransactionsState,
  countItems,
  typeNetwork,
  // ACTIONS
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
  getAdminUsers,
  permissions,
  getUserLocations,
  locations
}) => {
  const [chosenDate, setChosenDate] = useState([]);

  useEffect(() => {
    // const thisMonth = {
    //   dateFrom: moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:01',
    //   dateTo: moment().endOf('month').format('YYYY-MM-DD') + 'T23:59:59',
    // };
    if (permissions && permissions?.length > 0 && permissions[0]?.view) {
      getCryptoCurrencyVolume({
        typeNetwork: typeNetwork,
        dateFrom:
          chosenDate && chosenDate.length > 1
            ? moment(chosenDate[0]).format('YYYY-MM-DD') + 'T00:00:01'
            : moment(new Date(0)).format('YYYY-MM-DD') + 'T00:00:01',
        dateTo:
          chosenDate && chosenDate.length > 1
            ? moment(chosenDate[1]).format('YYYY-MM-DD') + 'T23:59:59'
            : moment().format('YYYY-MM-DD') + 'T23:59:59',
      });
      getCryptoCurrencyQuantity({
        typeNetwork: typeNetwork,
        dateFrom:
          chosenDate && chosenDate.length > 1
            ? moment(chosenDate[0]).format('YYYY-MM-DD') + 'T00:00:01'
            : moment(new Date(0)).format('YYYY-MM-DD') + 'T00:00:01',
        dateTo:
          chosenDate && chosenDate.length > 1
            ? moment(chosenDate[1]).format('YYYY-MM-DD') + 'T23:59:59'
            : moment().format('YYYY-MM-DD') + 'T23:59:59',
      });

      currencyVolumesTrxArray.forEach(trxType =>
        getCryptoCurrencyVolumeTrxByType({
          typeNetwork: typeNetwork,
          type: trxType,
          dateFrom:
            chosenDate && chosenDate.length > 1
              ? moment(chosenDate[0]).format('YYYY-MM-DD') + 'T00:00:01'
              : moment(new Date(0)).format('YYYY-MM-DD') + 'T00:00:01',
          dateTo:
            chosenDate && chosenDate.length > 1
              ? moment(chosenDate[1]).format('YYYY-MM-DD') + 'T23:59:59'
              : moment().format('YYYY-MM-DD') + 'T23:59:59',
        })
      );

      getAdminUsers();

      getUserLocations();
    }
    // getNewUsers(thisMonth);
    // getWithdrawTransactions({
    //   ...thisMonth,
    //   type: 'Withdrawal',
    //   typeNetwork: typeNetwork,
    // });
    // getReplenishTransactions({
    //   ...thisMonth,
    //   type: 'Replenishment',
    //   typeNetwork: typeNetwork,
    // });
    // getSystemFeeTransactions({
    //   ...thisMonth,
    //   type: 'SystemFee',
    //   typeNetwork: typeNetwork,
    // });
  }, [typeNetwork, chosenDate, permissions]);

  const [sharedDate, setSharedDate] = useState(
    // JSON.parse(localStorage.getItem('adm-date-all'))?.map(el => moment(el)) || null
    [moment().startOf('month'), moment().endOf('month')]
  );
  const [changedFieldsArray, setChangedFieldsArray] = useState([]);
  const [changeAllDataPickers, setChangeAllDataPickers] = useState(
    localStorage.getItem('dates-status') ? Boolean(localStorage.getItem('dates-status')) : false
  );
  const [datesForAllExceptUsers, setDatesForAllExceptUsers] = useState([
    moment().startOf('month'),
    moment().endOf('month'),
  ]);
  const adminDashboardRef = useRef();

  const textTooltipForCryptocurrency = 'The largest balances at the moment';
  const textTooltipForCryptocurrencyByTransaction =
    'Currencies for which the most transactions were made for the all time or for the selected time period';
  const textTooltipForCryptocurrencyOfReceive =
    'Currencies for which the largest number of replenishment were made in USD equivalent for all time or for the selected period of time';
  const textTooltipForCryptocurrencyOfWithdraw =
    'Currencies for which the largest number of withdrawal were made in USD equivalent for all time or for the selected period of time';
  const textTooltipForCryptocurrencyOfSystemFee =
    'Currencies for which the largest number of system fee were made in USD equivalent for all time or for the selected period of time';

  useEffect(() => {
    window.onbeforeunload = function () {
      return clearLocalStorage();
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (!changeAllDataPickers) {
      localStorage.removeItem('adm-date-all');
      localStorage.removeItem('dates-status');
      return;
    }

    const currentSharedData = JSON.parse(localStorage.getItem('adm-date-users'))?.map(el => moment(el)) || [
      moment().startOf('month'),
      moment().endOf('month'),
    ];
    // const currentSharedData = JSON.parse(localStorage.getItem('adm-date-users'))?.map(
    //   el => moment(el) || [moment().startOf('month'), moment().endOf('month')]
    // );
    setSharedDate(currentSharedData);
    setChangedFieldsArray([]);
    localStorage.removeItem('adm-date-users');
  }, [changeAllDataPickers]);

  useEffect(() => {
    if (changeAllDataPickers && sharedDate) {
      localStorage.setItem(
        `adm-date-all`,
        JSON.stringify([
          sharedDate[0]?.format('YYYY-MM-DD') + 'T00:00:01',
          sharedDate[1]?.format('YYYY-MM-DD') + 'T23:59:59',
        ])
      );
    }
  }, [sharedDate]);

  useEffect(() => {
    scrollContainerIntoView(adminDashboardRef);
  }, []);

  const changeChosenDate = date => {
    setChosenDate(date);
  };

  const onChangeHandler = e => {
    setChangeAllDataPickers(e.target.value);
    localStorage.setItem('dates-status', e.target.value);

    if (e.target.value === false) {
      setDatesForAllExceptUsers([moment().startOf('month'), moment().endOf('month')]);
      localStorage.setItem(
        'adm-date-users',
        JSON.stringify(JSON.parse(localStorage.getItem('adm-date-all'))?.map(el => moment(el)))
      );
    }
  };

  return (
    <>
      <div className="admin-dashboard-container" ref={adminDashboardRef}>
        <DashboardHeader changeChosenDate={changeChosenDate} />
        <TopFive
          title="Top 5 rated cryptocurrency"
          drawData={getCryptoCurrencyVolumeState}
          prefix={'$'}
          tooltipText={textTooltipForCryptocurrency}
        />
        {getCryptoCurrencyQuantityState.data && getCryptoCurrencyQuantityState.data.length ? (
          <TopFive
            title="Top 5 rated cryptocurrency by transaction "
            drawData={getCryptoCurrencyQuantityState}
            tooltipText={textTooltipForCryptocurrencyByTransaction}
          />
        ) : null}
        {getCryptoCurrencyVolumeTrxByTypeState?.data?.replenishment &&
        getCryptoCurrencyVolumeTrxByTypeState?.data?.replenishment?.length ? (
          <TopFive
            title="Top 5 rated cryptocurrency of receive"
            drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.replenishment }}
            prefix={'$'}
            tooltipText={textTooltipForCryptocurrencyOfReceive}
          />
        ) : null}
        {getCryptoCurrencyVolumeTrxByTypeState?.data?.withdrawal &&
        getCryptoCurrencyVolumeTrxByTypeState?.data?.withdrawal?.length ? (
          <TopFive
            title="Top 5 rated cryptocurrency of withdraw "
            drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.withdrawal }}
            prefix={'$'}
            tooltipText={textTooltipForCryptocurrencyOfWithdraw}
          />
        ) : null}
        {getCryptoCurrencyVolumeTrxByTypeState?.data?.systemfee &&
        getCryptoCurrencyVolumeTrxByTypeState?.data?.systemfee?.length ? (
          <TopFive
            title="Top 5 rated cryptocurrency of system fee"
            drawData={{ data: getCryptoCurrencyVolumeTrxByTypeState.data.systemfee }}
            prefix={'$'}
            tooltipText={textTooltipForCryptocurrencyOfSystemFee}
          />
        ) : null}
      </div>

      <div className="admin-tables-container">
        <TableData
          title="New users"
          drawData={getNewUsersState}
          propName="Users"
          action={getNewUsers}
          permissions={permissions}
          redirectTo={'/admin/users'}
          countItems={countItems}
          sharedDate={sharedDate}
          setSharedDate={setSharedDate}
          setChangedFieldsArray={setChangedFieldsArray}
          changedFieldsArray={changedFieldsArray}
          type="users"
          changeAllDataPickers={changeAllDataPickers}
          onChangeHandler={onChangeHandler}
          datesForAllExceptUsers={datesForAllExceptUsers}
        />
        <TableData
          title="Withdraw"
          drawData={getWithdrawTransactionsState}
          propName="Withdraw"
          type="Withdrawal"
          action={getWithdrawTransactions}
          permissions={permissions}
          prefix={'$'}
          typeNetwork={typeNetwork}
          setSharedDate={setSharedDate}
          sharedDate={sharedDate}
          setChangedFieldsArray={setChangedFieldsArray}
          changedFieldsArray={changedFieldsArray}
          changeAllDataPickers={changeAllDataPickers}
          datesForAllExceptUsers={datesForAllExceptUsers}
        />
        <TableData
          title="Replenishment"
          drawData={getReplenishTransactionsState}
          propName="Replenishment"
          type="Replenishment"
          action={getReplenishTransactions}
          permissions={permissions}
          prefix={'$'}
          typeNetwork={typeNetwork}
          setSharedDate={setSharedDate}
          sharedDate={sharedDate}
          setChangedFieldsArray={setChangedFieldsArray}
          changedFieldsArray={changedFieldsArray}
          changeAllDataPickers={changeAllDataPickers}
          datesForAllExceptUsers={datesForAllExceptUsers}
        />
        <TableData
          title="System fee"
          drawData={getSystemFeeTransactionsState}
          propName="System fee"
          type="SystemFee"
          action={getSystemFeeTransactions}
          permissions={permissions}
          prefix={'$'}
          typeNetwork={typeNetwork}
          setSharedDate={setSharedDate}
          sharedDate={sharedDate}
          setChangedFieldsArray={setChangedFieldsArray}
          changedFieldsArray={changedFieldsArray}
          changeAllDataPickers={changeAllDataPickers}
          datesForAllExceptUsers={datesForAllExceptUsers}
        />

        {locations?.length && (
          <div>
            <div style={{ fontSize: '18px', fontWeight: '600', color: '#000' }}>Geo Chart</div>
            <LocationData
              locations={locations}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  getCryptoCurrencyVolumeState: state.adminDashboard.getCryptoCurrencyVolume,
  getCryptoCurrencyQuantityState: state.adminDashboard.getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByTypeState: state.adminDashboard.getCryptoCurrencyVolumeTrxByType,
  getNewUsersState: state.adminDashboard.getNewUsers,
  getWithdrawTransactionsState: state.adminDashboard.getWithdrawTransactions,
  getReplenishTransactionsState: state.adminDashboard.getReplenishTransactions,
  getSystemFeeTransactionsState: state.adminDashboard.getSystemFeeTransactions,
  countItems: state.adminUsers.countItems,
  typeNetwork: state.networkFilter,
  permissions: state.adminSettings.permissions,
  locations: state.adminSettings.locations,
});

const mapDispatchToProps = {
  getCryptoCurrencyVolume,
  getCryptoCurrencyQuantity,
  getCryptoCurrencyVolumeTrxByType,
  getNewUsers,
  getWithdrawTransactions,
  getReplenishTransactions,
  getSystemFeeTransactions,
  getAdminUsers,
  getUserLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardPage);
