import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import MerchantWalletsList from '../../../components/WalletsPage/MerchantWalletsList';
import WalletCreateModal from "../../../components/WalletCreateModal/WalletCreateModal";
import PageHeader from "../../../layout/PageHeader";

const MerchantWallets = ({ networkFilter }) => {
  const { merchantId } = useParams();
  const { userId } = useParams();

  const WalletCreateComponent = () => (
    <WalletCreateModal
      merchantId={merchantId}
      typeWallet="merchant"
      filtersFormName="merchantWalletsFilters"
      networkFilter={networkFilter}
    />
  );

  return (
    <React.Fragment>
      <PageHeader showCreate={false} showBackButton={true} CreateModalComponent={WalletCreateComponent} />
      <div className="component" style={{ width: '100%'}}>
        <MerchantWalletsList
          formName="merchantWalletsFilters"
          merchantId={merchantId}
          typeWallet="merchant"
          networkFilter={networkFilter}
          type="admin"
          userId={userId}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
});

export default connect(mapStateToProps)(MerchantWallets);