import React, { useState, useEffect } from 'react';
import { identity } from "../../../utils/getIdentity";
import { Modal } from "antd";
import { ReactComponent as CloseSVG } from "../../../img/default-svg/close-black.svg";
import '../WebhooksWrapper/style.scss';
import '../../../layout/Modal.scss';
import { useTranslation } from "react-i18next";

const WebhookDataModal = ({ data, id, open, close }) => {
  const [currentData, seCurrentData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const newData = data?.filter((item) => item?._id === id);
    if (newData?.length > 0) {
      seCurrentData(newData[0]?.data);
    }
  }, [id]);

  return (
    <Modal
      width={620}
      title={t('webhooks.sentData')}
      visible={open}
      onCancel={close}
      footer={null}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="webhook-modal">
        {currentData && (
          <pre>{JSON.stringify(currentData, null, 2)}</pre>
        )}
      </div>
    </Modal>
  )
};

export default WebhookDataModal;