import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Select, Popover, Spin } from 'antd';
import TokenIcon from '../../TokenIcon';
import { cryptoApi } from '../../../service/cryptopay-api';
import formatCurrency from '../../../utils/currencyFormatter';
import { ReactComponent as SelectArrowIcon } from '../../../img/swap-svg/swap-select-arrow.svg';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import useWindowSize from "../../../utils/resizeHook";
import { useLocation } from "react-router-dom";
import { adminApi } from "../../../service/admin-api";

const SwapAmountField = ({
  currencies,
  merchantId,
  title,
  exchangeField,
  handleInputChange,
  handleCurrencyChange,
  merchantBalances,
  merchantBalance,
  disabled,
  walletFromId,
  exchangeWalletsFrom,
  withMax,
  swapOffers,
  priceForSwap,
  swapOffersFetching,
  swapOffersReset,
  setMaxAmountFetching,
  notificationsErrorShow,
  swapOffersUpdating,
  polkaDotLimitWarning,
  setPolkaDotLimitWarning,
  youSendFiledValue,
  setDataIsFrozen,
  isSwapStable,
  swapReceiveReset,
  password,
  sign,
  getWithdrawalAdminEstimateMax
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [showMaxButton, setShowMaxButton] = useState(false);
  const [decimalLimit, setDecimalLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const { t } = useTranslation();
  const { currentWidth } = useWindowSize();
  const location = useLocation();

  const isAdmin = location?.pathname === '/admin/swap/create';

  const transferFeeRef = useRef(null);
  const [numberOfLines, setNumberOfLines] = useState(0);

  const getMaxAmount = async () => {
    setMaxAmountFetching(true);
    const selectedWallet = exchangeWalletsFrom.entities.find(wallet => wallet._id === walletFromId);

    if (!isAdmin) {
      try {
        const response = await cryptoApi.withdrawalEstimateMax(merchantId, walletFromId, {
          to: selectedWallet.address,
          currencyToken: selectedWallet.tokens.length ? selectedWallet.tokens[0].currencyId : undefined,
          isSwap: true,
          password: password || undefined,
          sign: sign || undefined
        });

        if (response && response.status === 'success') {
          handleInputChange(`${response.data.amount}`, exchangeField.index);
        }
      } catch (error) {
        notificationsErrorShow({ message: error?.data?.message });
      }
    } else {
      try {
        const response = await adminApi.withdrawAdminSystemFeeEstimateMax(walletFromId, {
          to: selectedWallet.address,
          isSwap: true,
          currencyToken: selectedWallet.tokens.length ? selectedWallet.tokens[0].currencyId : undefined,
        });

        if (response && response.status === 'success') {
          handleInputChange(`${response.data.amount}`, exchangeField.index);
        }
      } catch (error) {
        notificationsErrorShow({ message: error?.data?.message });
      }
    }

    setMaxAmountFetching(false);
  };

  useEffect(() => {
    if (exchangeField.index === 0 && exchangeField.selectedCurrency) {
      setShowDecimalsWarning(false);
    }
  }, [exchangeField.index, exchangeField.selectedCurrency]);

  useEffect(() => {
    if (currencies && currencies.length && exchangeField.selectedCurrency) {
      setDecimalLimit(currencies.find(currency => currency._id === exchangeField.selectedCurrency)?.decimals);
    }
  }, [currencies, exchangeField.selectedCurrency]);

  useEffect(() => {
    if (isAdmin) {
      if (withMax && walletFromId && exchangeWalletsFrom.entities.length) {
        setShowMaxButton(true);
      } else {
        setShowMaxButton(false);
      }
    } else {
      if (withMax && merchantId && walletFromId && exchangeWalletsFrom.entities.length) {
        setShowMaxButton(true);
      } else {
        setShowMaxButton(false);
      }
    }
  }, [withMax, merchantId, walletFromId, exchangeWalletsFrom]);

  useEffect(() => {
    if (
      youSendFiledValue &&
      youSendFiledValue !== 0 &&
      exchangeField?.index === 1 &&
      exchangeField?.selectedCurrency === '62e7c3669c167b624bd84f37' &&
      exchangeField?.value < 1.03
    ) {
      setPolkaDotLimitWarning(true);
      return;
    }
    setPolkaDotLimitWarning(false);
  }, [exchangeField, setPolkaDotLimitWarning, youSendFiledValue]);

  const sortedBalances = merchantBalances
    .sort((balance1, balance2) => balance2.balance.usd - balance1.balance.usd)
    .filter(item => item.currency.name !== 'BNB');
  const supportedSwapCurrency = currencies.filter(currency => currency.supportSwap && currency.name !== 'BNB');

  const swaps = supportedSwapCurrency.map(m => {
    const currency = sortedBalances.filter(currency => currency.currency.id === m._id);
    return currency;
  });

  const getAbbreviationMainCurrency = chainSymbol => {
    switch (chainSymbol) {
      case 'ETH':
        return 'ETH';

      case 'BSC':
        return 'BNB';
      case 'TRX':
        return 'TRX';
      case 'POL':
        return 'MATIC';
      case 'ARB':
        return 'ETH ARB';
      case 'SOL':
        return 'SOL';

      default:
        return '';
    }
  };
  useEffect(() => {
    // Get the block height
    if (transferFeeRef?.current) {
      const divHeight = transferFeeRef.current.offsetHeight;

      const lineHeight = parseFloat(window.getComputedStyle(transferFeeRef.current).lineHeight);

      const calculatedNumberOfLines = divHeight / lineHeight;

      setNumberOfLines(Math.round(calculatedNumberOfLines));
    }
  }, [priceForSwap, exchangeField, getAbbreviationMainCurrency]);

  const swapOptions = swaps.flat(1).sort((a, b) => b?.balance?.usd - a?.balance?.usd); // .sort((a, b) => Number(b?.balance?.value) - Number(a?.balance?.value))

  return (
    <>
      <div
        className="swap-amount-field"
        style={{ paddingBottom: `${isSwapStable && numberOfLines > 1 ? '10px' : '3px'}`, width: `${currentWidth < 591 ? '100%' : 'auto'}` }}
      >
        <div className="swap-amount-field__title">
          {title}
          {showMaxButton && (
            <div
              className={`swap-amount-field__max swap-amount-field__max-${identity}`}
              onClick={!swapOffersUpdating && getMaxAmount}
            >
              Max
            </div>
          )}
        </div>
        <div className="swap-amount-field__input">
          <input
            type="number"
            placeholder={t('swap.enterAmount')}
            min={0}
            value={exchangeField.value}
            disabled={disabled || swapOffersUpdating}
            onChange={e => {
              setShowDecimalsWarning(false);

              if (+e.target.value < 0) {
                handleInputChange(0, exchangeField.index);
                exchangeField.index === 0 && handleInputChange(0, 1);
                swapOffersReset();
                swapReceiveReset();
                return;
              } else if (+e.target.value === 0) {
                exchangeField.index === 0 && handleInputChange(0, 1);
                swapOffersReset();
                swapReceiveReset();
              }

              if (!e.target.value.split('.')[1] || e.target.value.split('.')[1].length <= decimalLimit) {
                handleInputChange(e.target.value, exchangeField.index);
              } else {
                setShowDecimalsWarning(true);
                handleInputChange(exchangeField.value, exchangeField.index);
              }
            }}
          />

          <Select
            className={cn('swap-amount-field__select', {
              'swap-amount-field__select_opened': isDropdownOpened,
            })}
            disabled={swapOffersUpdating}
            showSearch={true}
            optionFilterProp="children"
            suffixIcon={!isDropdownOpened ? <SelectArrowIcon /> : null}
            open={isDropdownOpened}
            onDropdownVisibleChange={setIsDropdownOpened}
            value={
              // swapOptions.length ?
              merchantBalances.length ? (
                exchangeField.selectedCurrency
              ) : (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                      }}
                      spin
                    />
                  }
                />
              )
            }
            onChange={value => {
              handleCurrencyChange(value, exchangeField.index);
              setDataIsFrozen(false);
            }}
            dropdownClassName="swap-amount-field__dropdown"
            dropdownMatchSelectWidth={false}
            dropdownAlign={{
              points: ['tr', 'br'],
            }}
            filterOption={(input, option) => {
              return (option?.title ?? '').toLowerCase().includes(input.toLowerCase());
            }}
          >
            {/* {swapOptions?.map(currency =>
            <Select.Option value={currency.currency.id} title={currency.currency.title} key={currency.currency.id}>
            <div className="swap-amount-field__option-wrap">
              <div className="swap-amount-field__option-currency">
                <div className="swap-amount-field__token-icon">
                  <TokenIcon tokenName={currency.currency.name} />
                </div>
                {currency.currency.title}
              </div>
              <div className="swap-amount-field__option-balance">
                <span>{currency.balance.value}</span>
                <span>{formatCurrency(currency.balance.usd)}</span>
              </div>
            </div>
          </Select.Option>
            )} */}
            {merchantBalances.length &&
              merchantBalances
                // .sort((balance1, balance2) => balance2.balance.usd - balance1.balance.usd)
                .reduce((result, balance) => {
                  const currency = currencies.find(currency => currency._id === balance.currency.id);

                  if (
                    currency?.supportSwap &&
                    currency.name !== 'BNB'
                  ) {
                    result.push(
                      <Select.Option value={currency._id} title={currency.title} key={currency._id}>
                        <div className="swap-amount-field__option-wrap">
                          <div className="swap-amount-field__option-currency">
                            <div className="swap-amount-field__token-icon">
                              <TokenIcon tokenName={currency.name} />
                            </div>
                            {currency.title}
                          </div>
                          <div className="swap-amount-field__option-balance">
                            <span>{balance.balance.value}</span>
                            <span>{formatCurrency(balance.balance.usd)}</span>
                          </div>
                        </div>
                      </Select.Option>
                    );
                  }

                  return result;
                }, [])}
          </Select>
        </div>
        <div className="swap-amount-field__balance">
          {merchantBalance && merchantBalance.currency.name} {t('swap.exchange.balance')}{' '}
          <span className="swap-amount-field__amount">{merchantBalance ? merchantBalance.balance.value : '-'}</span>
        </div>
        {isSwapStable && (
          <div className="swap-amount-field__transferFee">
            {exchangeField.index === 0 && (
              <div ref={transferFeeRef}>
                {t('swap.exchange.transferFee')}{' '}
                <span className="swap-amount-field__amount">
                  {priceForSwap
                    ? `${priceForSwap?.txCount} ${getAbbreviationMainCurrency(exchangeField?.chainSymbol)}`
                    : '-'}
                </span>
              </div>
            )}
          </div>
        )}

        {exchangeField.index === 0 &&
          swapOffers &&
          swapOffers.length &&
          !swapOffersFetching &&
          swapOffers[0].fromAmount === +exchangeField.value &&
          +exchangeField.value < swapOffers[0].min &&
          !showDecimalsWarning && (
            <Popover title="" content={t('swap.clickToSelect')} className="detailed-checkout__popover">
              <span
                className="swap-amount-field__warning swap-amount-field__warning_min"
                onClick={() => handleInputChange(`${swapOffers[0].min}`, exchangeField.index)}
              >{`min ${swapOffers[0].min}`}</span>
            </Popover>
          )}

        {exchangeField.index === 0 && showDecimalsWarning && (
          <span
            className="swap-amount-field__warning swap-amount-field__warning"
            style={{ top: `${isSwapStable && numberOfLines > 1 ? 'calc(100% + 13px)' : '100%'}` }}
          >
            {`${t('swap.maxAmount')} ${
              currencies.find(currency => currency._id === exchangeField.selectedCurrency).name
            } ${t('swap.is')} ${decimalLimit}.`}
          </span>
        )}

        {exchangeField.index === 1 && polkaDotLimitWarning ? (
          <span className="swap-amount-field__warning swap-amount-field__warning">{t('swap.polkaDotLimit')}</span>
        ) : null}
      </div>
    </>
  );
};

export default SwapAmountField;
