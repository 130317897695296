import React from 'react';
import { useTranslation } from 'react-i18next';
import backCpayIcon from '../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../img/nfgpay-svg/arrow-back-nfg.svg';
import backFinvaroIcon from '../img/finvaro/arrow-back-finvaro.svg';
import backClarniumIcon from '../img/clarnium/arrow-back-clarnium.svg';
import { Link, useParams } from "react-router-dom";
import './PageHeader.scss';
import { identity } from '../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon,
  finvaro: backFinvaroIcon,
  clarnium: backClarniumIcon
}

export default function PageHeader({ pageHeaderText, CreateModalComponent, showBackButton, showCreate = true, merchants = false }) {
  const { t } = useTranslation();
  const { userId } = useParams();

  return (
    <div className={`page-header ${!showCreate && 'page-header--create'}`} align="middle">
      {CreateModalComponent && showCreate && <CreateModalComponent />}
      {pageHeaderText && <p className="page-header__tip">{pageHeaderText}</p>}
      {CreateModalComponent && showBackButton && (
        <Link to={showCreate ? "/merchants" : merchants ? '/admin/users' : `/admin/user-accounts/${userId}`} className={`page-header__back page-header__back-${identity}`}>
          <img src={backIcon[identity]} alt="Back" />
          <span>{t('goBack')}</span>
        </Link>
      )}
    </div>
  );
}
