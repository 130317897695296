import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import App from './App';
import store from './store';
import JwtDecode from 'jwt-decode';
import { softLogin } from './redux/actions/login';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet } from 'react-helmet';
import { AppConfig } from './config';

if (process.env.REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <>
    {AppConfig.identity === 'cpay' && (
      <Helmet>
        <title>CPAY Gateway Platform</title>

        <link rel="apple-touch-icon" sizes="57x57" href="cpay-icons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="cpay-icons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="cpay-icons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="cpay-icons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="cpay-icons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="cpay-icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="cpay-icons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="cpay-icons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="cpay-icons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="cpay-icons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="cpay-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="cpay-icons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="cpay-icons/favicon-16x16.png" />
        <link rel="shortcut icon" href="cpay-icons/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="cpay-icons/favicon.ico" type="image/x-icon" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="cpay-icons/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="facebook-domain-verification" content="x4w3agbj6v9fbbblha1hn8h4eii4pi" />

        <meta property="og:image:alt" content="" />
        <meta property="og:site_name" content="CPAY" />
        <meta property="og:type" content="object" />

        <meta property="og:title" content="CPAY Gateway Platform" />
        <meta name="twitter:title" content="CPAY Gateway Platform" />
        <meta name="DC.title" content="CPAY Gateway Platform" />
        <meta property="og:url" content="https://cpay.world/" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:image:src" content="cpay-icons/main-page.png" />

        <meta property="og:description" content="Manage crypto payments, wallets and transactions processing." />
        <meta name="twitter:description" content="Manage crypto payments, wallets and transactions processing." />
        <meta name="description" content="Manage crypto payments, wallets and transactions processing." />

        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="937" />

        <link rel="manifest" href="cpay-icons/manifest.json" crossorigin="use-credentials" />
      </Helmet>
    )}

    {AppConfig.identity === 'nfg' && (
      <Helmet>
        <title>Pay Your Bill with Crypto</title>

        <link rel="apple-touch-icon" sizes="57x57" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="nfgpay-icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="nfgpay-icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="nfgpay-icons/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="nfgpay-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="nfgpay-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="nfgpay-icons/favicon-16x16.png" />
        <link rel="shortcut icon" href="nfgpay-icons/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="nfgpay-icons/favicon.ico" type="image/x-icon" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="nfgpay-icons/mstile-150x150.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:image:alt" content="Select your preferred currency from our available options." />
        <meta property="og:site_name" content="NFGPAY" />
        <meta property="og:type" content="object" />

        <meta property="og:title" content="Pay Your Bill with Crypto" />
        <meta name="twitter:title" content="Pay Your Bill with Crypto" />
        <meta name="DC.title" content="Pay Your Bill with Crypto" />
        <meta property="og:url" content="https://app.crypto-now.io/" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:image:src" content="nfgpay-icons/android-chrome-512x512.png" />

        <meta property="og:description" content="Select your preferred currency from our available options." />
        <meta name="twitter:description" content="Select your preferred currency from our available options." />
        <meta name="description" content="Select your preferred currency from our available options." />

        <meta property="og:image:type" content="nfgpay-icons/android-chrome-512x512.png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />

        <link rel="manifest" href="nfgpay-icons/manifest.json" crossorigin="use-credentials" />
        {/*<script src="/tawk_nfg.js" type="text/javascript" />*/}
      </Helmet>
    )}

    {AppConfig.identity === 'finvaro' && (
      <Helmet>
        <title>Finvaro Crypto Processing</title>

        <link rel="apple-touch-icon" sizes="57x57" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="finvaro-icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="finvaro-icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="finvaro-icons/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="finvaro-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="finvaro-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="finvaro-icons/favicon-16x16.png" />
        <link rel="shortcut icon" href="finvaro-icons/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="finvaro-icons/favicon.ico" type="image/x-icon" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="finvaro-icons/mstile-150x150.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:image:alt" content="Select your preferred currency from our available options." />
        <meta property="og:site_name" content="Finvaro" />
        <meta property="og:type" content="object" />

        <meta property="og:title" content="Finvaro Crypto Processing" />
        <meta name="twitter:title" content="Finvaro Crypto Processing" />
        <meta name="DC.title" content="Finvaro Crypto Processing" />
        <meta property="og:url" content="https://crypto.finvaro.com/" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:image:src" content="finvaro-icons/android-chrome-512x512.png" />

        <meta property="og:description" content="Select your preferred currency from our available options." />
        <meta name="twitter:description" content="Select your preferred currency from our available options." />
        <meta name="description" content="Select your preferred currency from our available options." />

        <meta property="og:image:type" content="finvaro-icons/android-chrome-512x512.png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />

        <link rel="manifest" href="finvaro-icons/manifest.json" crossorigin="use-credentials" />
      </Helmet>
    )}

    {AppConfig.identity === 'clarnium' && (
      <Helmet>
        <title>Pay Your Bill with Crypto</title>

        <link rel="apple-touch-icon" sizes="57x57" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="clarnium-icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="clarnium-icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="clarnium-icons/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="clarnium-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="clarnium-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="clarnium-icons/favicon-16x16.png" />
        <link rel="shortcut icon" href="clarnium-icons/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="clarnium-icons/favicon.ico" type="image/x-icon" />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="clarnium-icons/mstile-150x150.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta property="og:image:alt" content="Select your preferred currency from our available options." />
        <meta property="og:site_name" content="Clarnium Pay" />
        <meta property="og:type" content="object" />

        <meta property="og:title" content="Pay Your Bill with Crypto" />
        <meta name="twitter:title" content="Pay Your Bill with Crypto" />
        <meta name="DC.title" content="Pay Your Bill with Crypto" />
        <meta property="og:url" content="https://app.clarnium.io/pay" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:image:src" content="clarnium-icons/android-chrome-512x512.png" />

        <meta property="og:description" content="Select your preferred currency from our available options." />
        <meta name="twitter:description" content="Select your preferred currency from our available options." />
        <meta name="description" content="Select your preferred currency from our available options." />

        <meta property="og:image:type" content="clarnium-icons/android-chrome-512x512.png" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />

        <link rel="manifest" href="clarnium-icons/manifest.json" crossorigin="use-credentials" />
      </Helmet>
    )}

    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
      <React.StrictMode>
        <Provider store={store}>
          {isLogedIn(store.dispatch)}
          <App />
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </>,

  document.getElementById('root')
);

function isLogedIn(dispatch) {
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    const decodedToken = JwtDecode(authToken);
    if (Date.now() < decodedToken.exp * 1000) dispatch(softLogin(authToken));
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
