import React, { useState, useEffect, useRef, createContext } from 'react';
import Header from './Header';
import Sider from './Sider';
import useWindowSize from '../utils/resizeHook';
import cn from 'classnames';
import './LayoutWrapper.scss';
import CpayTour from '../components/CpayTour';
import NewFooter from './NewFooter';
import { AppConfig } from '../config';
import { identity } from '../utils/getIdentity';
import { ReactComponent as CloseIcon } from "../img/default-svg/close-black.svg";

export const ScrollContainerContext = createContext();

const LayoutWrapper = ({ children, hideWizzardBlock, storedValue }) => {
  const { currentWidth } = useWindowSize();
  const LayoutWrapperRef = useRef(null);
  const [siderState, setSiderState] = useState(
    localStorage.getItem('sidebarMode') ? localStorage.getItem('sidebarMode') : false
  );

  useEffect(() => {
    if (currentWidth < 1360) {
      localStorage.setItem('sidebarMode', false);
      setSiderState(false);
    } else {
      localStorage.setItem('sidebarMode', true);
      setSiderState(true);
    }
  }, [currentWidth]);

  const toggleCollapseSider = () => {
    localStorage.setItem('sidebarMode', !siderState);
    setSiderState(!siderState);
  };
  return (
    <div className="main-layout">
      {currentWidth >= 1024 && <CpayTour />}
      <Header toggleCollapseSider={toggleCollapseSider} siderState={siderState} />
      <div className="main-layout__container">
        {currentWidth < 1360 && (
          <div
            className={cn('main-layout__box-shadow', { 'main-layout__box-shadow-opened': siderState })}
            onClick={toggleCollapseSider}
          />
        )}
        <Sider siderState={siderState} />
        <div className="main-layout__content-wrap" ref={LayoutWrapperRef}>
          {storedValue && AppConfig.showNftBlock && (
            <div className={`main-layout__content-wrap--close ${!siderState && 'main-layout__content-wrap--close-sider'}`}>
              <CloseIcon onClick={hideWizzardBlock} />
            </div>
          )}
          <div className={`main-layout__content main-layout__content-${identity}`}>
            <ScrollContainerContext.Provider
              value={{
                LayoutWrapperRef,
              }}
            >
              {/* <div className="warning">
                At the moment, maintenance work is underway on BTC, do not replenish or withdraw funds. Once we restore
                the network, this message will disappear
              </div> */}
              {children}
              {AppConfig.showFooter && <NewFooter />}
            </ScrollContainerContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutWrapper;
