import React , { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { Row, Col } from 'antd';
import './style.scss'

const LocationData = ({ locations }) => {
  const [chart, setChart] = useState([]);
  const [chartForTable, setChartForTable] = useState([]);

  const options = {
    displayMode: 'regions',
    colorAxis: {
      colors: ['#e0f3f8', '#4575b4'], // Від світло-блакитного до темно-синього
    },
  };

  const generateCountryCount = async (coordinates) => {
    const countryCounts = coordinates?.reduce((acc, country) => {
      acc[country] = (acc[country] || 0) + 1;
      return acc;
    }, {});

    const result = Object.entries(countryCounts);

    if (result?.length > 5) {
      setChartForTable(result?.sort((a, b) => b[1] - a[1]).slice(0, 5));
    } else {
      setChartForTable(result?.sort((a, b) => b[1] - a[1]));
    }

    setChart([['Country', 'Value'], ...result])
  };

  useEffect(() => {
    generateCountryCount(locations);
  }, [locations]);

  return (
    <div>
      <div style={{ display: 'flex', maxWidth: 900, margin: 'auto' }}>
        {chart?.length ? (
          <Chart
            chartType="GeoChart"
            width="100%"
            height="500px"
            data={chart}
            options={options}
          />
        ) : null}
      </div>
      {chartForTable?.length ? (
        <div className="table-container">
          <Row className="table-container--rowHeader">
            <Col xs={12} className="table-container--colHeader">
              Country
            </Col>
            <Col xs={12} className="table-container--colHeader">
              Traffic
            </Col>
          </Row>
          {chartForTable?.map(([countryCode, count], index) => (
            <Row key={index} className="table-container--rowTable">
              <Col xs={12} className="table-container--colTable">
                {countryCode}
              </Col>
              <Col xs={12} className="table-container--colTable">
                {count}
              </Col>
            </Row>
          ))}
        </div>
      ) : null}
    </div>
  )
};

export default LocationData;