import React from 'react';
import { Modal, Tooltip } from 'antd';
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import { ReactComponent as HelpCircleSVG } from '../../../img/default-svg/help-circle.svg';
import SelectType from './Steps/SelectType';
import CustomerInfo from './Steps/CustomerInfo';
import SetData from './Steps/SetData';
import DetailedCheckout from './Steps/DetailedCheckout';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const CheckoutsModals = ({
  visible,
  close,
  isDuplicating,
  checkoutCustomerInfo,
  setCheckoutCustomerInfo,
  setCheckoutType,
  checkoutType,
  createCheckoutHandler,
  nextStep,
  prevStep,
  step,
  type,
  editStep,
  setEditStep,
  detailedCheckoutState,
  editCheckoutState,
  editCheckoutHandler,
  ifCreateFetching,
  ifPatchFetching,
  activeMerchantCurrencies,
  merchantId,
  merchants,
  supportedCurrencies,
  supportedWallets,
  getSupportedCurrenciesList,
  getSupportedWalletsList,
  isRecurring
}) => {
  const { t } = useTranslation();

  const Steps = {
    0: {
      title: t(`checkouts.steps.${[step]}.title`),
      component: <SelectType setCheckoutType={setCheckoutType} nextStep={nextStep} />,
    },
    1: {
      title: t('checkouts.title'),
      component: (
        <CustomerInfo
          editCheckoutState={editCheckoutState}
          checkoutCustomerInfo={checkoutCustomerInfo}
          setCheckoutCustomerInfo={setCheckoutCustomerInfo}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ),
    },
    2: {
      title: t(`checkouts.steps.${[step]}.${[checkoutType]}.title`),
      component: (
        <SetData
          isDuplicating={isDuplicating}
          prevStep={prevStep}
          checkoutType={checkoutType}
          createCheckoutHandler={createCheckoutHandler}
          ifCreateFetching={ifCreateFetching}
          ifPatchFetching={ifPatchFetching}
          activeMerchantCurrencies={activeMerchantCurrencies}
          supportedCurrencies={supportedCurrencies}
          supportedWallets={supportedWallets}
          close={close}
          getSupportedCurrenciesList={getSupportedCurrenciesList}
          getSupportedWalletsList={getSupportedWalletsList}
        />
      ),
    },
  };

  const StepsPlan = {
    0: {
      title: t('checkouts.title'),
      component: (
        <CustomerInfo
          editCheckoutState={editCheckoutState}
          checkoutCustomerInfo={checkoutCustomerInfo}
          setCheckoutCustomerInfo={setCheckoutCustomerInfo}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ),
    },
    1: {
      title: t(`checkouts.plan.0.title`),
      component: (
        <SetData
          isDuplicating={isDuplicating}
          prevStep={prevStep}
          checkoutType={checkoutType}
          createCheckoutHandler={createCheckoutHandler}
          ifCreateFetching={ifCreateFetching}
          ifPatchFetching={ifPatchFetching}
          activeMerchantCurrencies={activeMerchantCurrencies}
          supportedCurrencies={supportedCurrencies}
          supportedWallets={supportedWallets}
          close={close}
          getSupportedCurrenciesList={getSupportedCurrenciesList}
          getSupportedWalletsList={getSupportedWalletsList}
        />
      ),
    },
  };

  const currentSteps = type === 'plan' ? StepsPlan : Steps;
  const isSecondStepInPlan = type === 'plan' && currentSteps === StepsPlan && step === 1;

  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className={`checkout-modal checkout-modal-${identity}`}
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="checkout-modal-content">
        {detailedCheckoutState ? (
          <>
            <div className="checkout-modal-content__title">
              {isRecurring
                ? t('checkouts.plan.0.modalTitle')
                : detailedCheckoutState.type === 'Donation'
                ? t('checkouts.steps.2.donation.title')
                : t('checkouts.steps.2.sale.title')}
            </div>
            <DetailedCheckout
              detailedCheckoutState={detailedCheckoutState}
              close={close}
              merchantId={merchantId}
              merchants={merchants}
              isRecurring={isRecurring}
            />
          </>
        ) : editCheckoutState ? (
          <>
            <div className="checkout-modal-content__title">
              {isRecurring
                ? t('checkouts.plan.0.modalTitle')
                : editCheckoutState.type === 'Donation'
                ? t('checkouts.steps.2.donation.title')
                : t('checkouts.steps.2.sale.title')}
            </div>
            {editStep === 0 ? (
              <CustomerInfo
                editCheckoutState={editCheckoutState}
                checkoutCustomerInfo={checkoutCustomerInfo}
                setCheckoutCustomerInfo={setCheckoutCustomerInfo}
                nextStep={() => setEditStep(1)}
              />
            ) : (
              <SetData
                isDuplicating={isDuplicating}
                prevStep={() => setEditStep(0)}
                createCheckoutHandler={isDuplicating ? createCheckoutHandler : editCheckoutHandler}
                editCheckoutState={editCheckoutState}
                ifCreateFetching={ifCreateFetching}
                ifPatchFetching={ifPatchFetching}
                activeMerchantCurrencies={activeMerchantCurrencies}
                supportedCurrencies={supportedCurrencies}
                supportedWallets={supportedWallets}
                close={close}
              />
            )}
          </>
        ) : (
          <>
            <div className="checkout-modal-content__title">
              {currentSteps[step]?.title}
              {isSecondStepInPlan && (
                <Tooltip
                  placement="bottomRight"
                  overlayClassName="checkout-modal-content__tooltip"
                  title={<span>{t('checkouts.plan.0.planSubscriptionInfo')}</span>}
                >
                  <HelpCircleSVG />
                </Tooltip>
              )}
            </div>
            {currentSteps[step]?.component}
          </>
        )}
      </div>
    </Modal>
  );
};

export default CheckoutsModals;
