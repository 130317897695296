import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tooltip, Popover } from 'antd';
import { tokenColors } from '../../../utils/tokenIcons';
import TokenIcon from '../../TokenIcon';
import formatCurrency from '../../../utils/currencyFormatter';
import useWindowSize from '../../../utils/resizeHook';
import { walletTruncate } from '../../../utils/truncateFunction';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Copy } from '../../../img/default-svg/copy.svg';
import { tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import { identity } from "../../../utils/getIdentity";

const Wallet = ({ wallet, selectedWallet, setSelectedWallet, getWalletInfo }) => {
  const [isTokensOpened, setIsTokensOpened] = useState(true);
  const [hasTokens, setHasTokens] = useState(false);
  const [walletTokens, setWalletTokens] = useState([]);
  const { currentWidth } = useWindowSize();
  const { t } = useTranslation();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const walletAddressRef = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState({});
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const addressPopoverTimer = useRef(null);
  const [popoverVisibility, setPopoverVisibility] = useState({});
  const [showTooltip, setShowTooltip] = useState({});

  const addressCopyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowAddressPopover(true);
    setShowTooltip({});

    addressPopoverTimer.current = setTimeout(() => {
      setShowAddressPopover(false);
      setPopoverVisibility({ ...popoverVisibility, [wallet._id]: false }); // Hide the Popover after 2 seconds
    }, 2000);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  const isSelected =
    selectedWallet && !selectedWallet.hasOwnProperty('holdBalance') && wallet._id === selectedWallet._id;

  useEffect(() => {
    const filtered = wallet.tokens;
    setHasTokens(!!filtered.length);
    setWalletTokens(filtered);
  }, [wallet]);

  useEffect(() => {
    if (
      !isTokensOpened &&
      selectedWallet &&
      selectedWallet.hasOwnProperty('holdBalance') &&
      selectedWallet.address === wallet.address
    ) {
      setSelectedWallet(null);
    }
  }, [isTokensOpened]);

  useEffect(() => {
    const element = walletAddressRef.current;

    const checkOverflow = () => {
      if (element) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      }
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [wallet.address, selectedWallet]);

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      addressPopoverTimer.current = null;
    };
  });

  return (
    <div
      className={`withdraw-wallets__wallet ${hasTokens ? 'withdraw-wallets__wallet_hasTokens' : ''}`}
      onMouseEnter={e => (e.currentTarget.style.borderColor = tokenColors(wallet.currency).color)}
      onMouseLeave={e =>
        (e.currentTarget.style.borderColor = (!hasTokens || !isTokensOpened) && isSelected ? 'transparent' : '#E0E6ED')
      }
      style={
        (!hasTokens || !isTokensOpened) && isSelected
          ? {
              background: tokenColors(wallet.currency).bgColor,
              borderColor: 'transparent',
            }
          : {
              background: 'transparent',
              borderColor: '#E0E6ED',
            }
      }
    >
      {hasTokens ? (
        <div
          className={`withdraw-wallets__dropdown-icon ${
            isTokensOpened ? 'withdraw-wallets__dropdown-icon_opened' : ''
          }`}
          onClick={() => setIsTokensOpened(!isTokensOpened)}
        >
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 6.12598L8 10.126L12 6.12598"
              stroke={tokenColors(wallet.currency).color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        ''
      )}
      <Row
        align="middle"
        onClick={() => {
          setSelectedWallet(wallet);
        }}
        style={
          hasTokens && isTokensOpened && isSelected
            ? {
                background: tokenColors(wallet.currency).bgColor,
                borderColor: 'transparent',
              }
            : {
                background: 'transparent',
                borderColor: '#E0E6ED',
              }
        }
      >
        <Col span={currentWidth >= 1024 ? 1 : 3}>
          <TokenIcon tokenName={wallet.currency} className="withdraw-wallets__logo" />
        </Col>
        <Col span={currentWidth >= 1024 ? 14 : 21}>
          <div className="withdraw-wallets__address" style={{ color: tokenColors(wallet.currency).color }}>
            <span className="withdraw-wallets__address-text" ref={walletAddressRef}>
              {' '}
              {wallet?.address}
            </span>

            {isOverflowing && (
              <Tooltip
                title={wallet.address}
                visible={tooltipVisibility[wallet._id]}
                onVisibleChange={isVisible => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: isVisible })}
              >
                <span
                  className="withdraw-wallets__address-mark"
                  onMouseEnter={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: true })}
                  onMouseLeave={() => setTooltipVisibility({ ...tooltipVisibility, [wallet._id]: false })}
                >
                  *
                </span>
              </Tooltip>
            )}
            {selectedWallet &&
            selectedWallet._id === wallet._id &&
            wallet.currency === selectedWallet.currency ? null : (
              <Tooltip
                title={t('clickToCopy')}
                placement="top"
                visible={showTooltip[wallet._id] || false}
                onVisibleChange={visible => setShowTooltip({ ...showTooltip, [wallet._id]: visible })}
              >
                <Popover
                  title=""
                  content={t('copied')}
                  trigger="click"
                  visible={popoverVisibility[wallet._id] || false}
                  onVisibleChange={visible => setPopoverVisibility({ ...popoverVisibility, [wallet._id]: visible })}
                >
                  <div
                    className="withdraw-wallets__address-copy"
                    onClick={e => {
                      e.stopPropagation();
                      addressCopyHandler(wallet.address);
                    }}
                  >
                    <Copy />
                  </div>
                </Popover>
              </Tooltip>
            )}

            {selectedWallet && selectedWallet._id === wallet._id && wallet.currency === selectedWallet.currency && (
              <button
                className={`withdraw-wallets__address-button withdraw-wallets__address-button-${identity}`}
                type="button"
                onClick={() => getWalletInfo(wallet._id, wallet.address)}
                disabled={wallet.balance.usd === 0}
              >
                {t('send')}
              </button>
            )}
          </div>
        </Col>
        <Col span={currentWidth >= 1024 ? 9 : 21} offset={currentWidth >= 1024 ? 0 : 3}>
          <div className="withdraw-wallets__balance">
            <div className="withdraw-wallets__balance-token">
              {wallet.balance.value} {wallet.currency}{' '}
              {tokenTypeEnd(wallet.currencyType, wallet.nodeType, wallet.currency)}
            </div>
            <div className="withdraw-wallets__balance-usd">{formatCurrency(wallet.balance.usd)}</div>
          </div>
        </Col>
      </Row>
      {isTokensOpened && (
        <div className="withdraw-wallets__tokens-dropdown">
          {walletTokens.map((token, index) => {
            const isTokenSelected =
              selectedWallet && selectedWallet.currencyId === token.currencyId && wallet._id === selectedWallet._id;

            return (
              <Row
                key={wallet._id + index}
                align="middle"
                className="withdraw-wallets__token"
                onClick={() => {
                  setSelectedWallet({ ...token, _id: wallet._id, address: wallet.address, nodeType: wallet.nodeType });
                }}
                style={{ background: isTokenSelected ? tokenColors(token.currency).bgColor : 'transparent' }}
              >
                <Col span={currentWidth >= 1024 ? 1 : 3}>
                  <TokenIcon tokenName={token.currency} className="withdraw-wallets__logo" />
                </Col>
                <Col span={currentWidth >= 1024 ? 13 : 21}>
                  <div className="withdraw-wallets__address" style={{ color: tokenColors(token.currency).color }}>
                    <span className="withdraw-wallets__address-text">{wallet.address}</span>
                    {selectedWallet &&
                    selectedWallet._id === wallet._id &&
                    selectedWallet.currency === token.currency ? null : (
                      <Tooltip
                        title={t('clickToCopy')}
                        visible={showTooltip[token.currency] || false}
                        onVisibleChange={visible => setShowTooltip({ ...showTooltip, [token.currency]: visible })}
                        placement="top"
                      >
                        <Popover
                          title=""
                          content={t('copied')}
                          trigger="click"
                          visible={popoverVisibility[token.currency] || false}
                          onVisibleChange={visible =>
                            setPopoverVisibility({ ...popoverVisibility, [token.currency]: visible })
                          }
                        >
                          <div
                            className="withdraw-wallets__address-copy"
                            onClick={e => {
                              e.stopPropagation();
                              addressCopyHandler(wallet.address);
                            }}
                          >
                            <Copy />
                          </div>
                        </Popover>
                      </Tooltip>
                    )}

                    {selectedWallet &&
                      selectedWallet._id === wallet._id &&
                      selectedWallet.currency === token.currency && (
                        <button
                          className={`withdraw-wallets__address-button withdraw-wallets__address-button-${identity}`}
                          type="button"
                          onClick={() => getWalletInfo(wallet._id, wallet.address)}
                        >
                          {t('send')}
                        </button>
                      )}
                  </div>
                </Col>
                <Col span={currentWidth >= 1024 ? 10 : 21} offset={currentWidth >= 1024 ? 0 : 3}>
                  <div className="withdraw-wallets__balance">
                    <div className="withdraw-wallets__balance-token">
                      {token.balance.value} {token.currency}
                    </div>
                    <div className="withdraw-wallets__balance-usd">{formatCurrency(token.balance.usd)}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Wallet;
