import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Menu, Tooltip, Dropdown } from 'antd';
import { showAppTour } from '../redux/actions/user';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import MerchantSelector from '../components/MerchantSelector/MerchantSelector';
import { ReactComponent as HomeIconSVG } from '../img/navigation/home-new.svg';
import { ReactComponent as MerchantsIconSVG } from '../img/navigation/merchants-new.svg';
import { ReactComponent as WalletsIconSVG } from '../img/navigation/wallet-new.svg';
import { ReactComponent as CheckoutsIconSVG } from '../img/navigation/checkoutsIcon-new.svg';
import { ReactComponent as RecurringIconSVG } from '../img/navigation/recurring.svg';
import { ReactComponent as WithdrawIconSVG } from '../img/navigation/withdraw-new.svg';
import { ReactComponent as SettingsIconSVG } from '../img/navigation/settings-new.svg';
import { ReactComponent as ProfileIconSVG } from '../img/default-svg/profileCircle.svg';
import { ReactComponent as NftIconSVG } from '../img/navigation/nft-new.svg';
import { ReactComponent as CpayCardSVG } from '../img/navigation/cpayCard.svg';
import { ReactComponent as SwapSVG } from '../img/navigation/swap-new.svg';
import { ReactComponent as TransactionHistorySVG } from '../img/navigation/transactionHistory.svg';
import { ReactComponent as RepeatGuideSVG } from '../img/navigation/cpayTourSidebar.svg';
import { ReactComponent as DocumentaionSVG } from '../img/nfgpay-svg/documentation.svg';
import { ReactComponent as DocumentaionSVGFinvaro } from '../img/finvaro/documentation.svg';
import { ReactComponent as DocumentaionSVGClarnium } from '../img/clarnium/documentation.svg';
import { ReactComponent as SupportSVG } from '../img/nfgpay-svg/help-circle.svg';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../utils/resizeHook';
import './Sider.scss';
import { AppConfig } from '../config';
import { identity } from '../utils/getIdentity';
import { useNftSupport } from '../utils/nftSupportContext';

function Sider({ siderState, userRole, setShowAppTour, userData }) {
  const { currentWidth } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { showNftSupportBlock } = useNftSupport();

  const linkForDocs = {
    cpay: 'support@cpay.world',
    nfg: 'support@crypto-now.io',
    finvaro: 'support@finvaro.com',
    clarnium: 'dev@clarnium.io'
  };

  const walletsMenu = (
    <Menu>
      <Menu.Item>
        <NavLink exact to={`/wallets`}>
          {t('merchants.merchantWallets')}
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink exact to={`/clients`}>
          {t('merchants.clientWallets')}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const Links = [
    {
      path: '/',
      text: t('home'),
      svg: <HomeIconSVG />,
      className: 'side-bar__home',
      id: 0,
    },
    {
      path: '/wallets',
      text: t('wallets.wallets'),
      svg: <WalletsIconSVG />,
      className: 'side-bar__wallets',
      id: 2,
      overlay: walletsMenu,
    },
    {
      path: '/checkouts',
      text: t('checkouts.checkouts'),
      svg: <CheckoutsIconSVG />,
      className: 'side-bar__checkouts',
      id: 3,
    },
    {
      path: '/withdraw',
      text: t('withdraw.withdraw'),
      svg: <WithdrawIconSVG />,
      className: 'side-bar__withdraw',
      id: 4,
    },
    {
      path: '/nft-wallet',
      text: t('nftWallet'),
      svg: <NftIconSVG />,
      className: 'side-bar__nft',
      id: 5,
      isVisible: showNftSupportBlock,
    },
    {
      path: '/swap',
      text: t('sideBar.swap'),
      svg: <SwapSVG />,
      className: 'side-bar__swap',
      id: 7,
    },
    {
      path: '/transactions',
      text: t('sideBar.transactionHistory'),
      svg: <TransactionHistorySVG />,
      className: 'side-bar__transaction',
      id: 8,
    },
    {
      path: '/settings',
      text: t('settings'),
      svg: <SettingsIconSVG />,
      className: 'side-bar__settings',
      id: 9,
    },
  ];

  const token = userData ? localStorage.getItem('authToken') : '';

  if (userRole?.includes('corporate') && !Links.find(link => link.path === '/merchants')) {
    Links.splice(1, 0, {
      path: '/merchants',
      text: t('merchants.merchants'),
      svg: <MerchantsIconSVG />,
      className: 'side-bar__merchants',
      id: 1,
    });
  }

  Links.splice(6, 0, {
    path: '/recurring-billing',
    text: t('checkouts.recurring'),
    svg: <RecurringIconSVG />,
    className: 'side-bar__checkouts',
    id: 6,
  })

  if (currentWidth < 1024) {
    if (!Links.find(link => link.path === '/profile')) {
      Links.unshift({
        path: '/profile',
        text: t('profile.profile'),
        svg: <ProfileIconSVG />,
        className: 'side-bar__profile',
        id: 10,
      });
    }
  }
  // remove My nft if nftSupport disabled
  const indexToRemove1 = Links.findIndex(link => link.isVisible === false);

  if (indexToRemove1 !== -1) {
    Links.splice(indexToRemove1, 1);
  }

  return (
    <div className={cn(`side-bar side-bar-${identity}`, { 'side-bar__closed': !siderState })}>
      <div>
        <div className="side-bar__block">
          {Links.map(link =>
            userRole?.includes('corporate') && link.overlay ? (
              <Dropdown key={link.id} overlay={link.overlay} placement="bottomLeft" arrow>
                <div className="side-bar__nav-link" key={link.id}>
                  <NavLink
                    exact={link.path !== '/swap'}
                    className={`side-bar__link side-bar__link-${identity}  ${link.className}`}
                    activeClassName={`side-bar__link_active side-bar__link_active-${identity}`}
                    to={link.path}
                  >
                    {link.svg}
                    <span>{link.text}</span>
                  </NavLink>
                </div>
              </Dropdown>
            ) : !siderState ? (
              <Tooltip
                key={link.id}
                title={link.text}
                placement="right"
                overlayClassName={`sider__nav-tooltip_${siderState}`}
              >
                <div className="side-bar__nav-link" key={link.id}>
                  <NavLink
                    exact={link.path !== '/swap'}
                    className={`side-bar__link side-bar__link-${identity} ${link.className}`}
                    activeClassName={`side-bar__link_active side-bar__link_active-${identity}`}
                    to={link.path}
                  >
                    {link.svg}
                    <span>{link.text}</span>
                  </NavLink>
                </div>
              </Tooltip>
            ) : (
              <div className="side-bar__nav-link" key={link.id}>
                <NavLink
                  exact={link.path !== '/swap'}
                  className={`side-bar__link side-bar__link-${identity} ${link.className}`}
                  activeClassName={`side-bar__link_active side-bar__link_active-${identity}`}
                  to={link.path}
                >
                  {link.svg}
                  <span>{link.text}</span>
                </NavLink>
              </div>
            )
          )}

          {/*{AppConfig.repeatGuide && currentWidth >= 1024 && (
            <>
              {!siderState ? (
                <Tooltip
                  title={t('sideBar.repeatGuide')}
                  placement="right"
                  overlayClassName={`sider__nav-tooltip_${siderState}`}
                >
                  <div className="side-bar__nav-link">
                    <div
                      className={`side-bar__link side-bar__link-${identity} side-bar__guide`}
                      onClick={() => setShowAppTour(true)}
                    >
                      <RepeatGuideSVG />
                      <span>{t('sideBar.repeatGuide')}</span>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <div className="side-bar__nav-link">
                  <div
                    className={`side-bar__link side-bar__link-${identity} side-bar__guide`}
                    onClick={() => setShowAppTour(true)}
                  >
                    <RepeatGuideSVG />
                    <span>{t('sideBar.repeatGuide')}</span>
                  </div>
                </div>
              )}
            </>
          )}*/}

          {/* {AppConfig.preOrderCard &&
          siderState ? (
            <div className={cn('side-bar__card-preorder', { es: i18n.language === 'es' })}>
              <a
                href={
                  token
                    ? `${process.env.REACT_APP_DEMO_CARD_API_URL}?token=${token}&lang=${
                        i18n.language !== 'en' ? i18n.language : undefined
                      }`
                    : `${process.env.REACT_APP_DEMO_CARD_API_URL}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="secondary">{t('sideBar.debitCard')}</Button>
              </a>
            </div>
          ) : (
            <div className="side-bar__card-preorder_icon">
              <a
                href={
                  token
                    ? `${process.env.REACT_APP_DEMO_CARD_API_URL}?token=${token}&lang=${
                        i18n.language !== 'en' ? i18n.language : undefined
                      }`
                    : `${process.env.REACT_APP_DEMO_CARD_API_URL}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <CpayCardSVG />
              </a>
            </div>
          )} */}

          <div className="side-bar__controls">
            {currentWidth < 1024 ? <MerchantSelector /> : null}
            <HeaderUser />
          </div>
        </div>
      </div>
      <div className={`side-bar__bottom-block side-bar__bottom-block-${identity}`}>
        {AppConfig.identity === 'cpay' && (
          <div className="side-bar__nav-link">
            <a className={`side-bar__link side-bar__link-${identity}`} href="https://docs.cpay.world" target="_blank" rel="noopener noreferrer">
              {' '}
              <DocumentaionSVGClarnium />
              <span>API Doc</span>
            </a>
          </div>
        )}
        {AppConfig.identity === 'nfg' && (
          <div className="side-bar__nav-link">
            <a className="side-bar__link" href="https://docs.crypto-now.io" target="_blank" rel="noopener noreferrer">
              {' '}
              <DocumentaionSVG />
              <span>Documentation</span>
            </a>
          </div>
        )}
        {AppConfig.identity === 'finvaro' && (
          <div className="side-bar__nav-link">
            <a className="side-bar__link" href="https://docs.finvaro.com" target="_blank" rel="noopener noreferrer">
              {' '}
              <DocumentaionSVGFinvaro />
              <span>Documentation</span>
            </a>
          </div>
        )}
        {AppConfig.identity === 'clarnium' && (
          <div className="side-bar__nav-link">
            <a className={`side-bar__link side-bar__link-${identity}`} href="https://docs.clarnium.io" target="_blank" rel="noopener noreferrer">
              {' '}
              <DocumentaionSVGClarnium />
              <span>Documentation</span>
            </a>
          </div>
        )}
        {identity !== 'finvaro' && (
          <div className="side-bar__nav-link">
            <a className={`side-bar__link side-bar__link-${identity} ${identity === 'nfg' && siderState && 'side-bar__link--padding'}`} href={`mailto:${linkForDocs[identity]}`}>
              <SupportSVG />
              <span>{linkForDocs[identity]}</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  userRole: state.user.data.roles,
  userData: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  setShowAppTour: data => dispatch(showAppTour(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sider);
