import React from 'react';
import { identity } from "../../utils/getIdentity";
import { Modal } from 'antd';
import '../../layout/Modal.scss';
import './WalletDeleteModal.scss';
import CopyWrapper from "../CopyWrapper";
import { useTranslation } from "react-i18next";
import Button from "../Button";

const AdminDataModal = ({ title, visible, close, data }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={close}
      footer={null}
      className={`modal modal-${identity}`}
    >
      <div className="adminData">
        <CopyWrapper copyContent={data}>
          {data}
        </CopyWrapper>

        <div className="modal__warning">
          <span className="modal__warning_red">{t('important')}</span>
          {title === 'Private key'
            ? t('merchants.clickToCopyPrivateKey')
            : title === 'Passphrase' ? t('merchants.clickToCopyPassphrase') : t('merchants.clickToCopyMnemonic')}
        </div>

        <div className="adminData--button">
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create-${identity} modal__button-create=${identity}`}
            onClick={close}
          >
            {t("done")}
          </Button>
        </div>
      </div>
    </Modal>
  )
};

export default AdminDataModal;