import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as formReducer } from 'redux-form';

import login from './redux/reducers/login';
import transactions from './redux/reducers/transactions';
import mainWallets from './redux/reducers/mainWallets';
import merchantWallets from './redux/reducers/merchantWallets';
import merchants from './redux/reducers/merchants';
import merchantData from './redux/reducers/merchantData';
import user from './redux/reducers/user';
import twoFactor from './redux/reducers/twoFactor';
import withdraw from './redux/reducers/withdraw';
import currencies from './redux/reducers/currencies';
import notifications from './redux/reducers/notifications';
import checkouts from './redux/reducers/checkouts';
import checkoutPayment from './redux/reducers/checkoutPayment';
import networkFilter from './redux/reducers/networkFilter';
import releaseNotes from './redux/reducers/releaseNotes';
import swap from './redux/reducers/swap';
import multisend from './redux/reducers/multisend';
import nft from './redux/reducers/nft';

// ADMIN REDUCERS
import adminDashboard from './redux/reducers/adminPanel/adminDashboard';
import adminSettings from './redux/reducers/adminPanel/adminSettings';
import adminUsers from './redux/reducers/adminPanel/adminUsers';
import adminFeeWallets from './redux/reducers/adminPanel/adminFeeWallets';
import adminFeeWithdraw from './redux/reducers/adminPanel/adminFeeWithdraw';
import adminFeeTransactions from './redux/reducers/adminPanel/adminFeeTransactions';
import adminUserTransactions from './redux/reducers/adminPanel/adminUserTransactions';
import adminTransactionsByUser from './redux/reducers/adminPanel/adminTransactionsByUser';
import adminUsersEmailsDownload from './redux/reducers/adminPanel/adminUsersEmailsDownload';
import adminMarketingInfoDownload from './redux/reducers/adminPanel/adminMarketingInfoDownload';
import adminCurrencyPrice from './redux/reducers/adminPanel/adminCurrencyPrice';
import adminFilters from './redux/reducers/adminPanel/adminFilters';
import adminSwap from './redux/reducers/adminPanel/adminSwap';

const appReducer = combineReducers({
  login,
  transactions,
  mainWallets,
  merchantWallets,
  merchants,
  merchantData,
  user,
  twoFactor,
  withdraw,
  currencies,
  notifications,
  checkouts,
  checkoutPayment,
  networkFilter,
  releaseNotes,
  swap,
  multisend,
  nft,
  form: formReducer,

  // ADMIN REDUCERS
  adminSettings,
  adminUsers,
  adminFeeWallets,
  adminFeeWithdraw,
  adminFeeTransactions,
  adminUserTransactions,
  adminDashboard,
  adminTransactionsByUser,
  adminUsersEmailsDownload,
  adminMarketingInfoDownload,
  adminCurrencyPrice,
  adminFilters,
  adminSwap
});

const rootReducer = (state, action) => {
  let newState = state;

  if (action.type === 'LOGIN_SIGN_OUT') {
    newState = { checkoutPayment: state.checkoutPayment };
  }

  return appReducer(newState, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
