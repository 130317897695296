import {
  MERCHANT_DATA_GET,
  MERCHANT_DATA_SET,
  MERCHANT_DATA_EDIT,
  MERCHANT_BALANCES_GET,
  MERCHANT_BALANCES_SET,
  MERCHANT_DATA_ERROR,
  GET_MERCHANT_API_KEYS_REQUEST,
  GET_MERCHANT_API_KEYS_SUCCESS,
  GET_MERCHANT_API_KEYS_FAIL,
  GET_API_SET_APP_TWO_FA,
  GET_API_SET_EMAIL_TWO_FA,
  GET_API_SET_PHONE_TWO_FA,
  GET_API_RESET_TWO_FA,
  GET_WEBHOOKS_LIST_REQUEST,
  GET_WEBHOOKS_LIST_SUCCESS,
  GET_WEBHOOKS_LIST_FAIL
} from '../actions/merchantData';

export default function reducer(
  state = {
    data: {},
    balances: {},
    apiKeys: {},
    fetching: false,
    twoFa: {
      isAppTwoFa: false,
      isEmailTwoFa: false,
      isPhoneTwoFa: false,
      fetching: false,
    },
    webhooksList: { data: null, fetching: false, page: 1, pages: null, countItem: null }
  },
  action
) {
  switch (action.type) {
    case MERCHANT_DATA_GET:
    case MERCHANT_DATA_EDIT:
    case MERCHANT_BALANCES_GET:
    case GET_MERCHANT_API_KEYS_REQUEST:
      return {
        ...state,
        fetching: action.fetching,
        apiKeys: {},
      };
    case GET_WEBHOOKS_LIST_REQUEST:
      return {
        ...state,
        webhooksList: {
          ...state.webhooksList,
          fetching: true
        }
      }
    case GET_WEBHOOKS_LIST_SUCCESS:
      return {
        ...state,
        webhooksList: {
          ...state.webhooksList,
          data: action.payload.entities,
          fetching: false,
          page: action.payload.page,
          pages: action.payload.pages,
          countItem: action.payload.countItems,
        }
      }
    case GET_WEBHOOKS_LIST_FAIL:
      return {
        ...state,
        webhooksList: {
          ...state.webhooksList,
          data: null,
          fetching: false,
          page: 1,
          pages: null,
          countItem: null,
        }
      }
    case MERCHANT_DATA_SET:
      return {
        ...state,
        data: action.payload,
        fetching: action.fetching,
        apiKeys: {},
      };
    case MERCHANT_BALANCES_SET:
      return {
        ...state,
        balances: action.payload,
        fetching: action.fetching,
      };
    case GET_MERCHANT_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: action.payload,
        fetching: action.fetching,
      };
    case MERCHANT_DATA_ERROR:
    case GET_MERCHANT_API_KEYS_FAIL:
      return {
        ...state,
        fetching: false,
      };

    case GET_API_SET_APP_TWO_FA:
      return {
        ...state,
        twoFa: {
          ...state.twoFa,
          isAppTwoFa: action.isAppTwoFa,
          fetching: false,
        },
        fetching: false,
      };
    case GET_API_SET_EMAIL_TWO_FA:
      return {
        ...state,
        twoFa: {
          ...state.twoFa,
          isEmailTwoFa: action.isEmailTwoFa,
          fetching: false,
        },
        fetching: false,
      };
    case GET_API_SET_PHONE_TWO_FA:
      return {
        ...state,
        twoFa: {
          ...state.twoFa,
          isPhoneTwoFa: action.isPhoneTwoFa,
          fetching: false,
        },
        fetching: false,
      };
    case GET_API_RESET_TWO_FA:
      return {
        ...state,
        twoFa: {
          ...state.twoFa,
          isAppTwoFa: false,
          isEmailTwoFa: false,
          isPhoneTwoFa: false,
          fetching: false,
        },
        fetching: false,
      };
    default:
      return state;
  }
}
