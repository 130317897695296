import React, { useEffect, useState } from "react";
import './style.scss';
import { useTranslation } from "react-i18next";
import { getMerchantData, getWebhooksList } from "../../../redux/actions/merchantData";
import { Dropdown, Input, Radio } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import WebhooksList from "../WebhooksList";
import ReactPaginate from 'react-paginate';
import WebhookDataModal from "../WebhookDataModal";
import { identity } from "../../../utils/getIdentity";
import resetCpayIcon from "../../../img/cpay-svg/reset.svg";
import resetNfgIcon from "../../../img/nfgpay-svg/reset-nfg.svg";
import resetFinvaroIcon from "../../../img/finvaro/reset-finvaro.svg";
import resetClarniumIcon from "../../../img/clarnium/reset-clarnium.svg";

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon,
  finvaro: resetFinvaroIcon,
  clarnium: resetClarniumIcon,
};

const WebhooksWrapper = ({ networkFilter, getWebhooksList, merchantId, webhooksList }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataId, setDataId] = useState('');
  const [isOpenDataModal, setIsOpenDataModal] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [webhookStatus, setWebhookStatus] = useState('true');
  const [type, setType] = useState('All');
  const [search, setSearch] = useState('');

  const resetSearch = () => {
    setSearch(null);
    getWebhooksListHandle();
  };

  const getWebhooksListHandle = async (page = 1, search = '') => {
    await getWebhooksList(merchantId, {
      typeNetwork: networkFilter,
      status: webhookStatus,
      page: page,
      type: type !== 'All' ? type : undefined,
      hash: search
    });
  };

  const closeModal = () => {
    setIsOpenDataModal(false);
    setDataId('');
  }

  const handlePageClick = (selectedPage) => {
    const pageNumber = selectedPage.selected + 1;
    setCurrentPage(selectedPage);
    getWebhooksListHandle(pageNumber);
  };

  useEffect(() => {
    if (merchantId) {
      getWebhooksListHandle();
    }
  }, [merchantId, networkFilter, webhookStatus, type]);

  useEffect(() => {
    if (dataId) {
      setIsOpenDataModal(true);
    }
  }, [dataId]);

  return (
    <div className="component webhooks">
      <div className="webhooks__header">
        <div className="webhooks__header--title">{t("webhooks.title")}</div>
        <div className="webhooks__dropdown">
          <Input.Search
            name="search"
            placeholder={t('webhooks.search')}
            onSearch={() => getWebhooksListHandle(1, search)}
            className="checkouts-header__search-field"
            onChange={e => setSearch(e.target.value)}
            value={search}
            addonAfter={
              <span onClick={resetSearch} className="checkouts-header__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
            }
          />
          <div className="webhooks__dropdown--wrapper">
            <Dropdown
              onVisibleChange={(visibilityState) => setIsTypeDropdownOpen(visibilityState)}
              visible={isTypeDropdownOpen}
              placement="bottomRight"
              overlay={
                <div className="webhooks__dropdown--item" style={{ padding: "10px" }}>
                  <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
                    <Radio value="All">{t('webhooks.all')}</Radio>
                    <Radio value="Replenishment">{t('webhooks.replenishment')}</Radio>
                    <Radio value="Multisend">{t('webhooks.multisend')}</Radio>
                    <Radio value="Withdrawal">{t('webhooks.withdrawal')}</Radio>
                    <Radio value="ExternalCall">{t('webhooks.external')}</Radio>
                    <Radio value="RecurringPaymentCancel">{t('webhooks.recurringCancel')}</Radio>
                    <Radio value="RecurringPaymentRenewal">{t('webhooks.recurringRenewal')}</Radio>
                    <Radio value="RecurringPaymentNew">{t('webhooks.recurringNew')}</Radio>
                  </Radio.Group>
                </div>
              }
            >
            <span className="webhooks__dropdown--button">
              {t('webhooks.type')} <DownOutlined />
            </span>
            </Dropdown>
          </div>
          <div className="webhooks__dropdown--wrapper">
            <Dropdown
              onVisibleChange={(visibilityState) => setIsStatusDropdownOpen(visibilityState)}
              visible={isStatusDropdownOpen}
              overlay={
                <div className="webhooks__dropdown--item" style={{ padding: "10px" }}>
                  <Radio.Group value={webhookStatus} onChange={(e) => setWebhookStatus(e.target.value)}>
                    <Radio value="true">{t('webhooks.statusTrue')}</Radio>
                    <Radio value="false">{t('webhooks.statusFalse')}</Radio>
                  </Radio.Group>
                </div>
              }
            >
            <span className="webhooks__dropdown--button">
              {t('webhooks.status')} <DownOutlined />
            </span>
            </Dropdown>
          </div>
        </div>
      </div>
      <WebhooksList
        data={webhooksList?.data}
        setDataId={setDataId}
      />
      {webhooksList?.data?.length > 0 && (
        <div>
          <ReactPaginate
            pageCount={webhooksList?.pages}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            forcePage={webhooksList?.page - 1}
          />
        </div>
      )}
      <WebhookDataModal
        data={webhooksList?.data}
        id={dataId}
        open={isOpenDataModal}
        close={closeModal}
      />
    </div>
  )
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
  merchantId: state.transactions.merchantId,
  webhooksList: state.merchantData.webhooksList
});

const mapDispatchToProps = dispatch => ({
  getMerchantData: getMerchantData(dispatch),
  getWebhooksList: getWebhooksList(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksWrapper);